export default [
    {
        "id": 1,
        "distillery": "Aberfeldy",
        "body": 2,
        "winey": 2,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 2,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 2,
        "fruity": 2,
        "nutty": 2,
        "malty": 2,
        "floral": 2,
        "Postcode": "PH15 2EB",
        "Latitude": 286580,
        "Longitude": 749680,
        "smoky_": 2,
        "delicate_": 6,
        "rich_": 5,
        "light_": 6,
        "label": "Aberfeldy",
        "Distillery": "Aberfeldy",
        "x": 8.533333333,
        "y": 11.66666667,
        "whiskys": 0
    },
    {
        "id": 2,
        "distillery": "Aberlour",
        "body": 3,
        "winey": 2,
        "spicy": 3,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 3,
        "honey": 4,
        "fruity": 3,
        "nutty": 2,
        "malty": 3,
        "floral": 2,
        "Postcode": "AB38 9PJ",
        "Latitude": 326340,
        "Longitude": 842570,
        "smoky_": 1,
        "delicate_": 7,
        "rich_": 8,
        "light_": 10,
        "label": "Aberlour",
        "Distillery": "Aberlour",
        "x": 6.066666667,
        "y": 10.26666667,
        "whiskys": 0
    },
    {
        "id": 3,
        "distillery": "AnCnoc",
        "body": 1,
        "winey": 0,
        "spicy": 0,
        "tobacco": 0,
        "smoky": 2,
        "medicinal": 0,
        "sweetness": 3,
        "honey": 2,
        "fruity": 3,
        "nutty": 2,
        "malty": 2,
        "floral": 2,
        "Postcode": "AB5 5LI",
        "Latitude": 352960,
        "Longitude": 839320,
        "smoky_": 2,
        "delicate_": 6,
        "rich_": 1,
        "light_": 8,
        "label": "AnCnoc",
        "Distillery": "AnCnoc",
        "x": 8.533333333,
        "y": 5.666666667,
        "whiskys": 0
    },
    {
        "id": 4,
        "distillery": "Ardbeg",
        "body": 4,
        "winey": 0,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 4,
        "medicinal": 4,
        "sweetness": 1,
        "honey": 0,
        "fruity": 1,
        "nutty": 1,
        "malty": 2,
        "floral": 0,
        "Postcode": "PA42 7EB",
        "Latitude": 141560,
        "Longitude": 646220,
        "smoky_": 8,
        "delicate_": 3,
        "rich_": 6,
        "light_": 2,
        "label": "Ardbeg",
        "Distillery": "Ardbeg",
        "x": 17.66666667,
        "y": 16.4,
        "whiskys": 0
    },
    {
        "id": 5,
        "distillery": "Ardmore",
        "body": 2,
        "winey": 1,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 2,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 1,
        "fruity": 1,
        "nutty": 2,
        "malty": 3,
        "floral": 1,
        "Postcode": "AB54 4NH",
        "Latitude": 355350,
        "Longitude": 829140,
        "smoky_": 2,
        "delicate_": 6,
        "rich_": 4,
        "light_": 4,
        "label": "Ardmore",
        "Distillery": "Ardmore",
        "x": 8.266666667,
        "y": 12.06666667,
        "whiskys": 0
    },
    {
        "id": 6,
        "distillery": "Auchentoshan",
        "body": 0,
        "winey": 0,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 0,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 1,
        "fruity": 3,
        "nutty": 2,
        "malty": 2,
        "floral": 3,
        "Postcode": "G81 4SJ",
        "Latitude": 247670,
        "Longitude": 672610,
        "smoky_": 0,
        "delicate_": 7,
        "rich_": 1,
        "light_": 6,
        "label": "Auchentoshan",
        "Distillery": "Auchentoshan",
        "x": 5.666666667,
        "y": 7,
        "whiskys": 0
    },
    {
        "id": 7,
        "distillery": "Auchroisk",
        "body": 2,
        "winey": 2,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 3,
        "honey": 2,
        "fruity": 2,
        "nutty": 2,
        "malty": 2,
        "floral": 1,
        "Postcode": "AB55 3XS",
        "Latitude": 340754,
        "Longitude": 848623,
        "smoky_": 1,
        "delicate_": 5,
        "rich_": 5,
        "light_": 7,
        "label": "Auchroisk",
        "Distillery": "Auchroisk",
        "x": 8.266666667,
        "y": 10.4,
        "whiskys": 0
    },
    {
        "id": 8,
        "distillery": "Aultmore",
        "body": 2,
        "winey": 0,
        "spicy": 0,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 1,
        "fruity": 2,
        "nutty": 2,
        "malty": 2,
        "floral": 2,
        "Postcode": "AB55 3QY",
        "Latitude": 340754,
        "Longitude": 848623,
        "smoky_": 1,
        "delicate_": 6,
        "rich_": 2,
        "light_": 5,
        "label": "Aultmore",
        "Distillery": "Aultmore",
        "x": 7.266666667,
        "y": 9.2,
        "whiskys": 0
    },
    {
        "id": 9,
        "distillery": "Balblair",
        "body": 2,
        "winey": 0,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 2,
        "medicinal": 1,
        "sweetness": 3,
        "honey": 0,
        "fruity": 2,
        "nutty": 2,
        "malty": 1,
        "floral": 1,
        "Postcode": "IV19 1LB",
        "Latitude": 270820,
        "Longitude": 885770,
        "smoky_": 3,
        "delicate_": 4,
        "rich_": 4,
        "light_": 5,
        "label": "Balblair",
        "Distillery": "Balblair",
        "x": 11.4,
        "y": 11.4,
        "whiskys": 0
    },
    {
        "id": 10,
        "distillery": "BenRiach",
        "body": 2,
        "winey": 0,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 2,
        "fruity": 3,
        "nutty": 0,
        "malty": 2,
        "floral": 2,
        "Postcode": "IV30 8SJ",
        "Latitude": 323450,
        "Longitude": 858380,
        "smoky_": 1,
        "delicate_": 4,
        "rich_": 4,
        "light_": 7,
        "label": "BenRiach",
        "Distillery": "BenRiach",
        "x": 9.2,
        "y": 9.333333333,
        "whiskys": 0
    },
    {
        "id": 11,
        "distillery": "Benrinnes",
        "body": 3,
        "winey": 1,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 2,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 3,
        "fruity": 2,
        "nutty": 2,
        "malty": 3,
        "floral": 2,
        "Postcode": "AB38 9NN",
        "Latitude": 325800,
        "Longitude": 839920,
        "smoky_": 2,
        "delicate_": 7,
        "rich_": 5,
        "light_": 7,
        "label": "Benrinnes",
        "Distillery": "Benrinnes",
        "x": 7.133333333,
        "y": 10.33333333,
        "whiskys": 0
    },
    {
        "id": 12,
        "distillery": "Benromach",
        "body": 2,
        "winey": 1,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 2,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 2,
        "fruity": 2,
        "nutty": 2,
        "malty": 2,
        "floral": 2,
        "Postcode": "IV36 3EB",
        "Latitude": 303330,
        "Longitude": 859350,
        "smoky_": 2,
        "delicate_": 6,
        "rich_": 5,
        "light_": 6,
        "label": "Benromach",
        "Distillery": "Benromach",
        "x": 8.266666667,
        "y": 11.6,
        "whiskys": 0
    },
    {
        "id": 13,
        "distillery": "Bladnoch",
        "body": 1,
        "winey": 1,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 0,
        "fruity": 2,
        "nutty": 0,
        "malty": 2,
        "floral": 3,
        "Postcode": "DG8 9AB",
        "Latitude": 242260,
        "Longitude": 554260,
        "smoky_": 1,
        "delicate_": 5,
        "rich_": 3,
        "light_": 4,
        "label": "Bladnoch",
        "Distillery": "Bladnoch",
        "x": 8.333333333,
        "y": 11,
        "whiskys": 0
    },
    {
        "id": 14,
        "distillery": "Blair Athol",
        "body": 2,
        "winey": 2,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 2,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 1,
        "fruity": 2,
        "nutty": 2,
        "malty": 2,
        "floral": 2,
        "Postcode": "PH16 5LY",
        "Latitude": 294860,
        "Longitude": 757580,
        "smoky_": 2,
        "delicate_": 6,
        "rich_": 6,
        "light_": 5,
        "label": "Blair Athol",
        "Distillery": "Blair Athol",
        "x": 8.533333333,
        "y": 13.6,
        "whiskys": 0
    },
    {
        "id": 15,
        "distillery": "Bowmore",
        "body": 2,
        "winey": 1,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 3,
        "medicinal": 1,
        "sweetness": 2,
        "honey": 2,
        "fruity": 1,
        "nutty": 1,
        "malty": 1,
        "floral": 2,
        "Postcode": "PA43 7GS",
        "Latitude": 131330,
        "Longitude": 659720,
        "smoky_": 4,
        "delicate_": 4,
        "rich_": 5,
        "light_": 5,
        "label": "Bowmore",
        "Distillery": "Bowmore",
        "x": 12.26666667,
        "y": 12.2,
        "whiskys": 0
    },
    {
        "id": 16,
        "distillery": "Bruichladdich",
        "body": 1,
        "winey": 1,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 2,
        "medicinal": 2,
        "sweetness": 1,
        "honey": 2,
        "fruity": 2,
        "nutty": 2,
        "malty": 2,
        "floral": 2,
        "Postcode": "PA49 7UN",
        "Latitude": 126680,
        "Longitude": 661400,
        "smoky_": 4,
        "delicate_": 6,
        "rich_": 4,
        "light_": 5,
        "label": "Bruichladdich",
        "Distillery": "Bruichladdich",
        "x": 10.46666667,
        "y": 11.13333333,
        "whiskys": 0
    },
    {
        "id": 17,
        "distillery": "Bunnahabhain",
        "body": 1,
        "winey": 1,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 1,
        "sweetness": 2,
        "honey": 1,
        "fruity": 2,
        "nutty": 1,
        "malty": 2,
        "floral": 3,
        "Postcode": "PA46 7RR",
        "Latitude": 142210,
        "Longitude": 673170,
        "smoky_": 2,
        "delicate_": 6,
        "rich_": 3,
        "light_": 5,
        "label": "Bunnahabhain",
        "Distillery": "Bunnahabhain",
        "x": 8.666666667,
        "y": 10.66666667,
        "whiskys": 0
    },
    {
        "id": 18,
        "distillery": "Caol Ila",
        "body": 3,
        "winey": 0,
        "spicy": 2,
        "tobacco": 1,
        "smoky": 4,
        "medicinal": 2,
        "sweetness": 1,
        "honey": 0,
        "fruity": 1,
        "nutty": 2,
        "malty": 1,
        "floral": 1,
        "Postcode": "PA46 7RL",
        "Latitude": 142920,
        "Longitude": 670040,
        "smoky_": 7,
        "delicate_": 4,
        "rich_": 5,
        "light_": 2,
        "label": "Caol Ila",
        "Distillery": "Caol Ila",
        "x": 15.53333333,
        "y": 15,
        "whiskys": 0
    },
    {
        "id": 19,
        "distillery": "Cardhu",
        "body": 1,
        "winey": 0,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 3,
        "honey": 1,
        "fruity": 2,
        "nutty": 2,
        "malty": 2,
        "floral": 2,
        "Postcode": "AB38 7RY",
        "Latitude": 318790,
        "Longitude": 843090,
        "smoky_": 1,
        "delicate_": 6,
        "rich_": 2,
        "light_": 6,
        "label": "Cardhu",
        "Distillery": "Cardhu",
        "x": 7.333333333,
        "y": 8.266666667,
        "whiskys": 0
    },
    {
        "id": 20,
        "distillery": "Clynelish",
        "body": 3,
        "winey": 0,
        "spicy": 2,
        "tobacco": 1,
        "smoky": 3,
        "medicinal": 3,
        "sweetness": 2,
        "honey": 0,
        "fruity": 2,
        "nutty": 1,
        "malty": 1,
        "floral": 0,
        "Postcode": "KW9 6LB",
        "Latitude": 290250,
        "Longitude": 904230,
        "smoky_": 7,
        "delicate_": 2,
        "rich_": 5,
        "light_": 4,
        "label": "Clynelish",
        "Distillery": "Clynelish",
        "x": 17.46666667,
        "y": 13.53333333,
        "whiskys": 0
    },
    {
        "id": 21,
        "distillery": "Dalmore",
        "body": 3,
        "winey": 2,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 2,
        "medicinal": 1,
        "sweetness": 2,
        "honey": 1,
        "fruity": 3,
        "nutty": 1,
        "malty": 2,
        "floral": 1,
        "Postcode": "IV17 0UT",
        "Latitude": 266610,
        "Longitude": 868730,
        "smoky_": 3,
        "delicate_": 4,
        "rich_": 7,
        "light_": 6,
        "label": "Dalmore",
        "Distillery": "Dalmore",
        "x": 11.2,
        "y": 13.33333333,
        "whiskys": 0
    },
    {
        "id": 22,
        "distillery": "Dalwhinnie",
        "body": 2,
        "winey": 0,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 2,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 2,
        "fruity": 2,
        "nutty": 1,
        "malty": 2,
        "floral": 2,
        "Postcode": "PH19 1AB",
        "Latitude": 263670,
        "Longitude": 785270,
        "smoky_": 2,
        "delicate_": 5,
        "rich_": 3,
        "light_": 6,
        "label": "Dalwhinnie",
        "Distillery": "Dalwhinnie",
        "x": 9.133333333,
        "y": 9.4,
        "whiskys": 0
    },
    {
        "id": 23,
        "distillery": "Deanston",
        "body": 2,
        "winey": 1,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 2,
        "fruity": 2,
        "nutty": 1,
        "malty": 3,
        "floral": 1,
        "Postcode": "FK16 6AG",
        "Latitude": 271570,
        "Longitude": 701570,
        "smoky_": 1,
        "delicate_": 5,
        "rich_": 4,
        "light_": 6,
        "label": "Deanston",
        "Distillery": "Deanston",
        "x": 8.6,
        "y": 10.26666667,
        "whiskys": 0
    },
    {
        "id": 24,
        "distillery": "Dufftown",
        "body": 2,
        "winey": 0,
        "spicy": 0,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 1,
        "sweetness": 3,
        "honey": 0,
        "fruity": 2,
        "nutty": 1,
        "malty": 2,
        "floral": 2,
        "Postcode": "AB55 4BR",
        "Latitude": 332360,
        "Longitude": 839200,
        "smoky_": 2,
        "delicate_": 5,
        "rich_": 2,
        "light_": 5,
        "label": "Dufftown",
        "Distillery": "Dufftown",
        "x": 9.6,
        "y": 9.466666667,
        "whiskys": 0
    },
    {
        "id": 25,
        "distillery": "Edradour",
        "body": 2,
        "winey": 1,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 3,
        "honey": 2,
        "fruity": 2,
        "nutty": 4,
        "malty": 2,
        "floral": 2,
        "Postcode": "PH16 5JP",
        "Latitude": 295960,
        "Longitude": 757940,
        "smoky_": 1,
        "delicate_": 8,
        "rich_": 4,
        "light_": 7,
        "label": "Edradour",
        "Distillery": "Edradour",
        "x": 5.4,
        "y": 9.4,
        "whiskys": 0
    },
    {
        "id": 26,
        "distillery": "Glen Deveron Macduff",
        "body": 2,
        "winey": 2,
        "spicy": 1,
        "tobacco": 1,
        "smoky": 1,
        "medicinal": 1,
        "sweetness": 3,
        "honey": 1,
        "fruity": 0,
        "nutty": 0,
        "malty": 2,
        "floral": 1,
        "Postcode": "AB4 3JT",
        "Latitude": 372120,
        "Longitude": 860400,
        "smoky_": 3,
        "delicate_": 3,
        "rich_": 5,
        "light_": 4,
        "label": "Glen Deveron Macduff",
        "Distillery": "Glen Deveron Macduff",
        "x": 12.13333333,
        "y": 13.46666667,
        "whiskys": 0
    },
    {
        "id": 27,
        "distillery": "Glen Elgin",
        "body": 2,
        "winey": 1,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 3,
        "honey": 2,
        "fruity": 2,
        "nutty": 1,
        "malty": 1,
        "floral": 3,
        "Postcode": "IV30 3SL",
        "Latitude": 322640,
        "Longitude": 861040,
        "smoky_": 1,
        "delicate_": 5,
        "rich_": 4,
        "light_": 7,
        "label": "Glen Elgin",
        "Distillery": "Glen Elgin",
        "x": 8.333333333,
        "y": 9.6,
        "whiskys": 0
    },
    {
        "id": 28,
        "distillery": "Glen Garioch",
        "body": 2,
        "winey": 1,
        "spicy": 3,
        "tobacco": 0,
        "smoky": 3,
        "medicinal": 0,
        "sweetness": 1,
        "honey": 0,
        "fruity": 2,
        "nutty": 0,
        "malty": 2,
        "floral": 2,
        "Postcode": "AB51 0ES",
        "Latitude": 381020,
        "Longitude": 827590,
        "smoky_": 3,
        "delicate_": 4,
        "rich_": 6,
        "light_": 3,
        "label": "Glen Garioch",
        "Distillery": "Glen Garioch",
        "x": 11.06666667,
        "y": 15.66666667,
        "whiskys": 0
    },
    {
        "id": 29,
        "distillery": "Glen Grant",
        "body": 1,
        "winey": 1,
        "spicy": 0,
        "tobacco": 0,
        "smoky": 0,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 1,
        "fruity": 2,
        "nutty": 2,
        "malty": 1,
        "floral": 1,
        "Postcode": "AB38 7BS",
        "Latitude": 327610,
        "Longitude": 849570,
        "smoky_": 0,
        "delicate_": 4,
        "rich_": 2,
        "light_": 5,
        "label": "Glen Grant",
        "Distillery": "Glen Grant",
        "x": 8.2,
        "y": 9.666666667,
        "whiskys": 0
    },
    {
        "id": 30,
        "distillery": "Glen Moray",
        "body": 1,
        "winey": 1,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 1,
        "fruity": 2,
        "nutty": 2,
        "malty": 2,
        "floral": 4,
        "Postcode": "IV30 1YE",
        "Latitude": 319820,
        "Longitude": 862320,
        "smoky_": 1,
        "delicate_": 8,
        "rich_": 4,
        "light_": 5,
        "label": "Glen Moray",
        "Distillery": "Glen Moray",
        "x": 5.333333333,
        "y": 11.2,
        "whiskys": 0
    },
    {
        "id": 31,
        "distillery": "Glen Ord",
        "body": 3,
        "winey": 1,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 1,
        "fruity": 2,
        "nutty": 1,
        "malty": 2,
        "floral": 2,
        "Postcode": "IV6 7UJ",
        "Latitude": 251810,
        "Longitude": 850860,
        "smoky_": 1,
        "delicate_": 5,
        "rich_": 6,
        "light_": 5,
        "label": "Glen Ord",
        "Distillery": "Glen Ord",
        "x": 8.333333333,
        "y": 13.53333333,
        "whiskys": 0
    },
    {
        "id": 32,
        "distillery": "Glen Scotia",
        "body": 2,
        "winey": 1,
        "spicy": 0,
        "tobacco": 0,
        "smoky": 2,
        "medicinal": 2,
        "sweetness": 2,
        "honey": 1,
        "fruity": 1,
        "nutty": 2,
        "malty": 2,
        "floral": 1,
        "Postcode": "PA28 6DS",
        "Latitude": 172090,
        "Longitude": 621010,
        "smoky_": 4,
        "delicate_": 5,
        "rich_": 3,
        "light_": 4,
        "label": "Glen Scotia",
        "Distillery": "Glen Scotia",
        "x": 11.26666667,
        "y": 11.4,
        "whiskys": 0
    },
    {
        "id": 33,
        "distillery": "Glen Spey",
        "body": 1,
        "winey": 1,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 3,
        "honey": 0,
        "fruity": 0,
        "nutty": 1,
        "malty": 2,
        "floral": 2,
        "Postcode": "AB38 7AU",
        "Latitude": 327760,
        "Longitude": 849140,
        "smoky_": 1,
        "delicate_": 5,
        "rich_": 3,
        "light_": 3,
        "label": "Glen Spey",
        "Distillery": "Glen Spey",
        "x": 8.466666667,
        "y": 12.6,
        "whiskys": 0
    },
    {
        "id": 34,
        "distillery": "Glendronach",
        "body": 4,
        "winey": 4,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 2,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 2,
        "fruity": 2,
        "nutty": 2,
        "malty": 2,
        "floral": 0,
        "Postcode": "AB54 6DA",
        "Latitude": 361200,
        "Longitude": 844930,
        "smoky_": 2,
        "delicate_": 4,
        "rich_": 9,
        "light_": 6,
        "label": "Glendronach",
        "Distillery": "Glendronach",
        "x": 10.33333333,
        "y": 15.13333333,
        "whiskys": 0
    },
    {
        "id": 35,
        "distillery": "Glendullan",
        "body": 3,
        "winey": 2,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 2,
        "fruity": 3,
        "nutty": 1,
        "malty": 2,
        "floral": 2,
        "Postcode": "AB55 4DJ",
        "Latitude": 333000,
        "Longitude": 840300,
        "smoky_": 1,
        "delicate_": 5,
        "rich_": 6,
        "light_": 7,
        "label": "Glendullan",
        "Distillery": "Glendullan",
        "x": 8.6,
        "y": 11.53333333,
        "whiskys": 0
    },
    {
        "id": 36,
        "distillery": "Glenfarclas",
        "body": 2,
        "winey": 3,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 4,
        "honey": 1,
        "fruity": 2,
        "nutty": 2,
        "malty": 3,
        "floral": 2,
        "Postcode": "AB37 9BD",
        "Latitude": 320950,
        "Longitude": 838160,
        "smoky_": 1,
        "delicate_": 7,
        "rich_": 7,
        "light_": 7,
        "label": "Glenfarclas",
        "Distillery": "Glenfarclas",
        "x": 6.2,
        "y": 12.46666667,
        "whiskys": 0
    },
    {
        "id": 37,
        "distillery": "Glenfiddich",
        "body": 1,
        "winey": 0,
        "spicy": 0,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 3,
        "honey": 0,
        "fruity": 2,
        "nutty": 0,
        "malty": 2,
        "floral": 2,
        "Postcode": "AB55 4DH",
        "Latitude": 332680,
        "Longitude": 840840,
        "smoky_": 1,
        "delicate_": 4,
        "rich_": 1,
        "light_": 5,
        "label": "Glenfiddich",
        "Distillery": "Glenfiddich",
        "x": 9.133333333,
        "y": 8.2,
        "whiskys": 0
    },
    {
        "id": 38,
        "distillery": "Glengoyne",
        "body": 1,
        "winey": 1,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 0,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 1,
        "fruity": 3,
        "nutty": 2,
        "malty": 2,
        "floral": 2,
        "Postcode": "G63 9LB",
        "Latitude": 252810,
        "Longitude": 682750,
        "smoky_": 0,
        "delicate_": 6,
        "rich_": 3,
        "light_": 6,
        "label": "Glengoyne",
        "Distillery": "Glengoyne",
        "x": 6.066666667,
        "y": 9.466666667,
        "whiskys": 0
    },
    {
        "id": 39,
        "distillery": "Glenkinchie",
        "body": 1,
        "winey": 0,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 1,
        "fruity": 2,
        "nutty": 0,
        "malty": 2,
        "floral": 2,
        "Postcode": "EH34 5ET",
        "Latitude": 344380,
        "Longitude": 666690,
        "smoky_": 1,
        "delicate_": 4,
        "rich_": 3,
        "light_": 5,
        "label": "Glenkinchie",
        "Distillery": "Glenkinchie",
        "x": 9.533333333,
        "y": 10.6,
        "whiskys": 0
    },
    {
        "id": 40,
        "distillery": "Glenlivet",
        "body": 2,
        "winey": 2,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 3,
        "honey": 2,
        "fruity": 2,
        "nutty": 1,
        "malty": 2,
        "floral": 3,
        "Postcode": "AB37 9DB",
        "Latitude": 319560,
        "Longitude": 828780,
        "smoky_": 1,
        "delicate_": 6,
        "rich_": 6,
        "light_": 7,
        "label": "Glenlivet",
        "Distillery": "Glenlivet",
        "x": 7.6,
        "y": 11.66666667,
        "whiskys": 0
    },
    {
        "id": 41,
        "distillery": "Glenlossie",
        "body": 1,
        "winey": 0,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 1,
        "fruity": 2,
        "nutty": 1,
        "malty": 2,
        "floral": 2,
        "Postcode": "IV30 3SS",
        "Latitude": 322640,
        "Longitude": 861040,
        "smoky_": 1,
        "delicate_": 5,
        "rich_": 3,
        "light_": 5,
        "label": "Glenlossie",
        "Distillery": "Glenlossie",
        "x": 8.4,
        "y": 10.06666667,
        "whiskys": 0
    },
    {
        "id": 42,
        "distillery": "Glenmorangie",
        "body": 2,
        "winey": 0,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 1,
        "sweetness": 2,
        "honey": 1,
        "fruity": 2,
        "nutty": 2,
        "malty": 1,
        "floral": 2,
        "Postcode": "IV19 1PZ",
        "Latitude": 276750,
        "Longitude": 883450,
        "smoky_": 2,
        "delicate_": 5,
        "rich_": 4,
        "light_": 5,
        "label": "Glenmorangie",
        "Distillery": "Glenmorangie",
        "x": 9.2,
        "y": 11.26666667,
        "whiskys": 0
    },
    {
        "id": 43,
        "distillery": "Glenrothes",
        "body": 2,
        "winey": 2,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 3,
        "honey": 1,
        "fruity": 2,
        "nutty": 1,
        "malty": 2,
        "floral": 0,
        "Postcode": "AB38 7AA",
        "Latitude": 327650,
        "Longitude": 849170,
        "smoky_": 1,
        "delicate_": 3,
        "rich_": 5,
        "light_": 6,
        "label": "Glenrothes",
        "Distillery": "Glenrothes",
        "x": 10.6,
        "y": 11,
        "whiskys": 0
    },
    {
        "id": 44,
        "distillery": "Glenturret",
        "body": 2,
        "winey": 2,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 3,
        "honey": 2,
        "fruity": 1,
        "nutty": 2,
        "malty": 2,
        "floral": 2,
        "Postcode": "PH7 4HA",
        "Latitude": 285630,
        "Longitude": 723580,
        "smoky_": 1,
        "delicate_": 6,
        "rich_": 6,
        "light_": 6,
        "label": "Glenturret",
        "Distillery": "Glenturret",
        "x": 7.533333333,
        "y": 12.33333333,
        "whiskys": 0
    },
    {
        "id": 45,
        "distillery": "Highland Park",
        "body": 2,
        "winey": 1,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 3,
        "medicinal": 1,
        "sweetness": 2,
        "honey": 2,
        "fruity": 1,
        "nutty": 1,
        "malty": 2,
        "floral": 1,
        "Postcode": "KW15 1SU",
        "Latitude": 345340,
        "Longitude": 1009260,
        "smoky_": 4,
        "delicate_": 4,
        "rich_": 4,
        "light_": 5,
        "label": "Highland Park",
        "Distillery": "Highland Park",
        "x": 12.66666667,
        "y": 11.66666667,
        "whiskys": 0
    },
    {
        "id": 46,
        "distillery": "Inchgower",
        "body": 1,
        "winey": 0,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 1,
        "sweetness": 3,
        "honey": 2,
        "fruity": 1,
        "nutty": 1,
        "malty": 2,
        "floral": 2,
        "Postcode": "AB56 5AB",
        "Latitude": 342610,
        "Longitude": 863970,
        "smoky_": 2,
        "delicate_": 5,
        "rich_": 3,
        "light_": 6,
        "label": "Inchgower",
        "Distillery": "Inchgower",
        "x": 9.333333333,
        "y": 9.666666667,
        "whiskys": 0
    },
    {
        "id": 47,
        "distillery": "Isle of Jura",
        "body": 2,
        "winey": 0,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 2,
        "medicinal": 2,
        "sweetness": 1,
        "honey": 1,
        "fruity": 1,
        "nutty": 2,
        "malty": 1,
        "floral": 1,
        "Postcode": "PA60 7XT",
        "Latitude": 152660,
        "Longitude": 667040,
        "smoky_": 4,
        "delicate_": 4,
        "rich_": 3,
        "light_": 3,
        "label": "Isle of Jura",
        "Distillery": "Isle of Jura",
        "x": 12.33333333,
        "y": 12.06666667,
        "whiskys": 0
    },
    {
        "id": 48,
        "distillery": "Lagavulin",
        "body": 4,
        "winey": 2,
        "spicy": 1,
        "tobacco": 1,
        "smoky": 4,
        "medicinal": 4,
        "sweetness": 1,
        "honey": 0,
        "fruity": 1,
        "nutty": 1,
        "malty": 1,
        "floral": 0,
        "Postcode": "PA42 7DZ",
        "Latitude": 140430,
        "Longitude": 645730,
        "smoky_": 9,
        "delicate_": 2,
        "rich_": 7,
        "light_": 2,
        "label": "Lagavulin",
        "Distillery": "Lagavulin",
        "x": 19.06666667,
        "y": 17.53333333,
        "whiskys": 0
    },
    {
        "id": 49,
        "distillery": "Laphroaig",
        "body": 4,
        "winey": 1,
        "spicy": 0,
        "tobacco": 1,
        "smoky": 4,
        "medicinal": 4,
        "sweetness": 2,
        "honey": 0,
        "fruity": 0,
        "nutty": 1,
        "malty": 1,
        "floral": 0,
        "Postcode": "PA42 7DU",
        "Latitude": 138680,
        "Longitude": 645160,
        "smoky_": 9,
        "delicate_": 2,
        "rich_": 5,
        "light_": 2,
        "label": "Laphroaig",
        "Distillery": "Laphroaig",
        "x": 19.46666667,
        "y": 15.66666667,
        "whiskys": 0
    },
    {
        "id": 50,
        "distillery": "Linkwood",
        "body": 2,
        "winey": 2,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 3,
        "honey": 1,
        "fruity": 3,
        "nutty": 0,
        "malty": 1,
        "floral": 2,
        "Postcode": "IV30 3RD",
        "Latitude": 322640,
        "Longitude": 861040,
        "smoky_": 1,
        "delicate_": 3,
        "rich_": 5,
        "light_": 7,
        "label": "Linkwood",
        "Distillery": "Linkwood",
        "x": 10.13333333,
        "y": 10.2,
        "whiskys": 0
    },
    {
        "id": 51,
        "distillery": "Loch Lomond",
        "body": 1,
        "winey": 0,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 1,
        "sweetness": 1,
        "honey": 1,
        "fruity": 1,
        "nutty": 1,
        "malty": 2,
        "floral": 2,
        "Postcode": "G83 0TL",
        "Latitude": 239370,
        "Longitude": 680920,
        "smoky_": 2,
        "delicate_": 5,
        "rich_": 2,
        "light_": 3,
        "label": "Loch Lomond",
        "Distillery": "Loch Lomond",
        "x": 9.2,
        "y": 11.46666667,
        "whiskys": 0
    },
    {
        "id": 52,
        "distillery": "Longmorn",
        "body": 3,
        "winey": 1,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 1,
        "fruity": 2,
        "nutty": 3,
        "malty": 3,
        "floral": 3,
        "Postcode": "IV30 3SJ",
        "Latitude": 322640,
        "Longitude": 861040,
        "smoky_": 1,
        "delicate_": 9,
        "rich_": 5,
        "light_": 5,
        "label": "Longmorn",
        "Distillery": "Longmorn",
        "x": 4.133333333,
        "y": 12.66666667,
        "whiskys": 0
    },
    {
        "id": 53,
        "distillery": "Macallan",
        "body": 4,
        "winey": 4,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 3,
        "honey": 2,
        "fruity": 3,
        "nutty": 2,
        "malty": 2,
        "floral": 1,
        "Postcode": "AB38 9RX",
        "Latitude": 327710,
        "Longitude": 844480,
        "smoky_": 1,
        "delicate_": 5,
        "rich_": 9,
        "light_": 8,
        "label": "Macallan",
        "Distillery": "Macallan",
        "x": 8.066666667,
        "y": 13.66666667,
        "whiskys": 0
    },
    {
        "id": 54,
        "distillery": "Mannochmore",
        "body": 2,
        "winey": 1,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 1,
        "honey": 1,
        "fruity": 2,
        "nutty": 2,
        "malty": 1,
        "floral": 2,
        "Postcode": "IV30 3SS",
        "Latitude": 322640,
        "Longitude": 861040,
        "smoky_": 1,
        "delicate_": 5,
        "rich_": 4,
        "light_": 4,
        "label": "Mannochmore",
        "Distillery": "Mannochmore",
        "x": 8.2,
        "y": 12.33333333,
        "whiskys": 0
    },
    {
        "id": 55,
        "distillery": "Miltonduff",
        "body": 2,
        "winey": 0,
        "spicy": 0,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 4,
        "honey": 1,
        "fruity": 1,
        "nutty": 2,
        "malty": 1,
        "floral": 2,
        "Postcode": "IV30 3TQ",
        "Latitude": 322640,
        "Longitude": 861040,
        "smoky_": 1,
        "delicate_": 5,
        "rich_": 2,
        "light_": 6,
        "label": "Miltonduff",
        "Distillery": "Miltonduff",
        "x": 8.6,
        "y": 8.266666667,
        "whiskys": 0
    },
    {
        "id": 56,
        "distillery": "Mortlach",
        "body": 3,
        "winey": 3,
        "spicy": 3,
        "tobacco": 0,
        "smoky": 2,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 2,
        "fruity": 2,
        "nutty": 2,
        "malty": 1,
        "floral": 2,
        "Postcode": "AB55 4AQ",
        "Latitude": 332950,
        "Longitude": 839850,
        "smoky_": 2,
        "delicate_": 5,
        "rich_": 9,
        "light_": 6,
        "label": "Mortlach",
        "Distillery": "Mortlach",
        "x": 9.666666667,
        "y": 15.06666667,
        "whiskys": 0
    },
    {
        "id": 57,
        "distillery": "Oban",
        "body": 2,
        "winey": 0,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 2,
        "medicinal": 2,
        "sweetness": 2,
        "honey": 0,
        "fruity": 2,
        "nutty": 2,
        "malty": 2,
        "floral": 0,
        "Postcode": "PA34 5NH",
        "Latitude": 185940,
        "Longitude": 730190,
        "smoky_": 4,
        "delicate_": 4,
        "rich_": 4,
        "light_": 4,
        "label": "Oban",
        "Distillery": "Oban",
        "x": 12,
        "y": 12.13333333,
        "whiskys": 0
    },
    {
        "id": 58,
        "distillery": "Old Pulteney",
        "body": 2,
        "winey": 1,
        "spicy": 1,
        "tobacco": 1,
        "smoky": 2,
        "medicinal": 2,
        "sweetness": 1,
        "honey": 0,
        "fruity": 2,
        "nutty": 2,
        "malty": 2,
        "floral": 2,
        "Postcode": "KW1 5BA",
        "Latitude": 336730,
        "Longitude": 950130,
        "smoky_": 5,
        "delicate_": 6,
        "rich_": 4,
        "light_": 3,
        "label": "Old Pulteney",
        "Distillery": "Old Pulteney",
        "x": 11.46666667,
        "y": 13.13333333,
        "whiskys": 0
    },
    {
        "id": 59,
        "distillery": "Royal Brackla",
        "body": 2,
        "winey": 1,
        "spicy": 2,
        "tobacco": 1,
        "smoky": 2,
        "medicinal": 1,
        "sweetness": 3,
        "honey": 1,
        "fruity": 3,
        "nutty": 0,
        "malty": 2,
        "floral": 2,
        "Postcode": "IV12 5QY",
        "Latitude": 286040,
        "Longitude": 851320,
        "smoky_": 4,
        "delicate_": 4,
        "rich_": 5,
        "light_": 7,
        "label": "Royal Brackla",
        "Distillery": "Royal Brackla",
        "x": 12.13333333,
        "y": 10.13333333,
        "whiskys": 0
    },
    {
        "id": 60,
        "distillery": "Royal Lochnagar",
        "body": 3,
        "winey": 2,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 2,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 2,
        "fruity": 3,
        "nutty": 2,
        "malty": 2,
        "floral": 1,
        "Postcode": "AB35 5TB",
        "Latitude": 326140,
        "Longitude": 794370,
        "smoky_": 2,
        "delicate_": 5,
        "rich_": 7,
        "light_": 7,
        "label": "Royal Lochnagar",
        "Distillery": "Royal Lochnagar",
        "x": 9.333333333,
        "y": 12.66666667,
        "whiskys": 0
    },
    {
        "id": 61,
        "distillery": "Scapa",
        "body": 2,
        "winey": 1,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 1,
        "sweetness": 2,
        "honey": 2,
        "fruity": 2,
        "nutty": 2,
        "malty": 2,
        "floral": 2,
        "Postcode": "KW15 1SE",
        "Latitude": 342850,
        "Longitude": 1008930,
        "smoky_": 2,
        "delicate_": 6,
        "rich_": 4,
        "light_": 6,
        "label": "Scapa",
        "Distillery": "Scapa",
        "x": 8.066666667,
        "y": 10.13333333,
        "whiskys": 0
    },
    {
        "id": 62,
        "distillery": "Speyburn",
        "body": 2,
        "winey": 0,
        "spicy": 1,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 4,
        "honey": 2,
        "fruity": 1,
        "nutty": 0,
        "malty": 2,
        "floral": 2,
        "Postcode": "AB38 7AG",
        "Latitude": 326930,
        "Longitude": 851430,
        "smoky_": 1,
        "delicate_": 4,
        "rich_": 3,
        "light_": 7,
        "label": "Speyburn",
        "Distillery": "Speyburn",
        "x": 9.4,
        "y": 8.466666667,
        "whiskys": 0
    },
    {
        "id": 63,
        "distillery": "Speyside",
        "body": 2,
        "winey": 1,
        "spicy": 0,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 1,
        "fruity": 2,
        "nutty": 2,
        "malty": 2,
        "floral": 2,
        "Postcode": "PH21 1NS",
        "Latitude": 278740,
        "Longitude": 800600,
        "smoky_": 1,
        "delicate_": 6,
        "rich_": 3,
        "light_": 5,
        "label": "Speyside",
        "Distillery": "Speyside",
        "x": 7.6,
        "y": 10.26666667,
        "whiskys": 0
    },
    {
        "id": 64,
        "distillery": "Springbank",
        "body": 2,
        "winey": 1,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 2,
        "medicinal": 2,
        "sweetness": 2,
        "honey": 2,
        "fruity": 0,
        "nutty": 2,
        "malty": 1,
        "floral": 1,
        "Postcode": "PA28 6EJ",
        "Latitude": 172280,
        "Longitude": 620910,
        "smoky_": 4,
        "delicate_": 4,
        "rich_": 5,
        "light_": 4,
        "label": "Springbank",
        "Distillery": "Springbank",
        "x": 12.33333333,
        "y": 13.06666667,
        "whiskys": 0
    },
    {
        "id": 65,
        "distillery": "Strathisla",
        "body": 2,
        "winey": 2,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 2,
        "fruity": 3,
        "nutty": 3,
        "malty": 3,
        "floral": 2,
        "Postcode": "AB55 3BS",
        "Latitude": 340754,
        "Longitude": 848623,
        "smoky_": 1,
        "delicate_": 8,
        "rich_": 6,
        "light_": 7,
        "label": "Strathisla",
        "Distillery": "Strathisla",
        "x": 5.066666667,
        "y": 11.13333333,
        "whiskys": 0
    },
    {
        "id": 66,
        "distillery": "Strathmill",
        "body": 2,
        "winey": 0,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 3,
        "honey": 0,
        "fruity": 3,
        "nutty": 2,
        "malty": 1,
        "floral": 2,
        "Postcode": "AB55 5DQ",
        "Latitude": 342650,
        "Longitude": 850500,
        "smoky_": 1,
        "delicate_": 5,
        "rich_": 4,
        "light_": 6,
        "label": "Strathmill",
        "Distillery": "Strathmill",
        "x": 8.6,
        "y": 10.46666667,
        "whiskys": 0
    },
    {
        "id": 67,
        "distillery": "Talisker",
        "body": 4,
        "winey": 0,
        "spicy": 3,
        "tobacco": 0,
        "smoky": 3,
        "medicinal": 3,
        "sweetness": 2,
        "honey": 1,
        "fruity": 2,
        "nutty": 1,
        "malty": 2,
        "floral": 0,
        "Postcode": "IV47 8SR",
        "Latitude": 137950,
        "Longitude": 831770,
        "smoky_": 6,
        "delicate_": 3,
        "rich_": 7,
        "light_": 5,
        "label": "Talisker",
        "Distillery": "Talisker",
        "x": 15,
        "y": 14.6,
        "whiskys": 0
    },
    {
        "id": 68,
        "distillery": "Tamdhu",
        "body": 1,
        "winey": 1,
        "spicy": 0,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 2,
        "fruity": 2,
        "nutty": 1,
        "malty": 2,
        "floral": 2,
        "Postcode": "AB38 7RP",
        "Latitude": 319210,
        "Longitude": 841760,
        "smoky_": 1,
        "delicate_": 5,
        "rich_": 2,
        "light_": 6,
        "label": "Tamdhu",
        "Distillery": "Tamdhu",
        "x": 8.066666667,
        "y": 8.466666667,
        "whiskys": 0
    },
    {
        "id": 69,
        "distillery": "Tamnavulin",
        "body": 1,
        "winey": 0,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 2,
        "medicinal": 0,
        "sweetness": 3,
        "honey": 0,
        "fruity": 2,
        "nutty": 2,
        "malty": 1,
        "floral": 3,
        "Postcode": "AB37 9JA",
        "Latitude": 321180,
        "Longitude": 826110,
        "smoky_": 2,
        "delicate_": 6,
        "rich_": 3,
        "light_": 5,
        "label": "Tamnavulin",
        "Distillery": "Tamnavulin",
        "x": 8.466666667,
        "y": 10.53333333,
        "whiskys": 0
    },
    {
        "id": 70,
        "distillery": "Teaninich",
        "body": 2,
        "winey": 0,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 2,
        "medicinal": 1,
        "sweetness": 2,
        "honey": 0,
        "fruity": 2,
        "nutty": 0,
        "malty": 0,
        "floral": 2,
        "Postcode": "IV17 0XB",
        "Latitude": 265360,
        "Longitude": 869120,
        "smoky_": 3,
        "delicate_": 2,
        "rich_": 4,
        "light_": 4,
        "label": "Teaninich",
        "Distillery": "Teaninich",
        "x": 13.26666667,
        "y": 12.66666667,
        "whiskys": 0
    },
    {
        "id": 71,
        "distillery": "Tobermory",
        "body": 1,
        "winey": 0,
        "spicy": 0,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 1,
        "honey": 1,
        "fruity": 2,
        "nutty": 1,
        "malty": 2,
        "floral": 2,
        "Postcode": "PA75 6NR",
        "Latitude": 150450,
        "Longitude": 755070,
        "smoky_": 1,
        "delicate_": 5,
        "rich_": 1,
        "light_": 4,
        "label": "Tobermory",
        "Distillery": "Tobermory",
        "x": 8.266666667,
        "y": 9.266666667,
        "whiskys": 0
    },
    {
        "id": 72,
        "distillery": "Tomatin",
        "body": 2,
        "winey": 1,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 2,
        "medicinal": 0,
        "sweetness": 3,
        "honey": 2,
        "fruity": 0,
        "nutty": 1,
        "malty": 2,
        "floral": 1,
        "Postcode": "IV13 7YT",
        "Latitude": 279120,
        "Longitude": 829630,
        "smoky_": 2,
        "delicate_": 4,
        "rich_": 5,
        "light_": 5,
        "label": "Tomatin",
        "Distillery": "Tomatin",
        "x": 10,
        "y": 12.46666667,
        "whiskys": 0
    },
    {
        "id": 73,
        "distillery": "Tomintoul",
        "body": 0,
        "winey": 1,
        "spicy": 2,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 3,
        "honey": 2,
        "fruity": 1,
        "nutty": 1,
        "malty": 2,
        "floral": 2,
        "Postcode": "AB37 9AQ",
        "Latitude": 315100,
        "Longitude": 825560,
        "smoky_": 1,
        "delicate_": 5,
        "rich_": 3,
        "light_": 6,
        "label": "Tomintoul",
        "Distillery": "Tomintoul",
        "x": 8.466666667,
        "y": 9.133333333,
        "whiskys": 0
    },
    {
        "id": 74,
        "distillery": "Tormore",
        "body": 2,
        "winey": 1,
        "spicy": 0,
        "tobacco": 0,
        "smoky": 1,
        "medicinal": 0,
        "sweetness": 2,
        "honey": 1,
        "fruity": 0,
        "nutty": 2,
        "malty": 1,
        "floral": 0,
        "Postcode": "PH26 3LR",
        "Latitude": 315180,
        "Longitude": 834960,
        "smoky_": 1,
        "delicate_": 3,
        "rich_": 3,
        "light_": 3,
        "label": "Tormore",
        "Distillery": "Tormore",
        "x": 10.66666667,
        "y": 12.66666667,
        "whiskys": 0
    },
    {
        "id": 75,
        "distillery": "Tullibardine",
        "body": 2,
        "winey": 1,
        "spicy": 2,
        "tobacco": 1,
        "smoky": 0,
        "medicinal": 0,
        "sweetness": 3,
        "honey": 0,
        "fruity": 2,
        "nutty": 1,
        "malty": 2,
        "floral": 1,
        "Postcode": "PH4 1QG",
        "Latitude": 289690,
        "Longitude": 708850,
        "smoky_": 1,
        "delicate_": 4,
        "rich_": 5,
        "light_": 5,
        "label": "Tullibardine",
        "Distillery": "Tullibardine",
        "x": 9.333333333,
        "y": 12.53333333,
        "whiskys": 0
    }
]