import React, { Component } from 'react';
import './App.css';
import './data/data.csv'
import * as d3 from 'd3';
import Sanky from 'd3.chart.sankey';
import 'd3.chart';
import whiskys from './data/whiskys'
import distilleryProfiles from './data/profiles2.js';
import xyData from './data/xydata.js'
import { costs, colors, clusters } from './data/datalabels'

let selectedDistilleryData = {
  Distillery: "Glenlivet",
  Latitude: "319560",
  Longitude: "828780",
  Postcode: "AB37 9DB",
  body: "2",
  delicate_: "6",
  distillery: "Glenlivet",
  floral: "3",
  fruity: "2",
  honey: "2",
  id: "40",
  label: "Glenlivet",
  light_: "7",
  malty: "2",
  medicinal: "0",
  nutty: "1",
  rich_: "6",
  smoky: "1",
  smoky_: "1",
  spicy: "2",
  sweetness: "3",
  tobacco: "0",
  whiskys: "0",
  winey: "2",
  x: "7.6",
  y: "11.66666667"
}

let allProfiles = distilleryProfiles;

let whiskyData = {
  clusters: [],
  whiskys: [],
  distilleryProfiles: [],
  selectedDistillery: "Glenlivet",
  selectedDistilleryData,
  selectedCost: costs[3],
  selectedCluster: clusters[0],
  currentClusters: [],
  scroll: false,
  profiles: clusters,
  checked: true,
  costs,
  paginated: {}
}

class App extends Component {

  constructor(props) {
    super(props);
    this.drawScatter = this.drawScatter.bind(this);
    this.createLinks = this.createLinks.bind(this);
    this.createNodes = this.createNodes.bind(this);
    this.drawSankey = this.drawSankey.bind(this);
    this.drawRadar = this.drawRadar.bind(this);
    this.getDistilleryWhiskys = this.getDistilleryWhiskys.bind(this);
    this.getWhiskyByCluster = this.getWhiskyByCluster.bind(this);
    this.handleSelectDistillery = this.handleSelectDistillery.bind(this);
    this.createSankey = this.createSankey.bind(this);
    this.handleSelectCost = this.handleSelectCost.bind(this);
    this.myRef = React.createRef();
    this.sankyRef = React.createRef();
    this.TableRows = this.TableRows.bind(this);

    this.state = {
      currentClusters: []
    }
  }

  /**
   * Parse sankey data and render diagrams
   */
  componentWillMount() {
    this.parseProfilesCSV()
      .then(allProfiles => {
        return this.parseWhiskysCSV(allProfiles)
      })
      .then(allWhiskys => { this.createSankey(whiskyData.selectedDistillery, allWhiskys) })
  }

  componentDidUpdate() {
    if (whiskyData.scroll) {
      window.scrollTo(0, this.myRef.current.offsetTop)

      whiskyData.scroll = false;
    }
    if (whiskyData.selectedDistilleryData !== undefined) {
      let data = this.processRadarData(whiskyData.selectedDistilleryData)
      this.drawRadar(data);
    }
  }

  /**
   * Take profiles csv and put into a JSON array
   * 
   * Resolves a promise with list of all profiles
   */
  parseProfilesCSV = () => {
    return new Promise((resolve, reject) => {
      whiskyData.distilleryProfiles = [...allProfiles]
      resolve(allProfiles)
    })
  }

  /**
   * Take the whisky csv, and attach data from the parsed profiles data
   * 
   * Resolves a promise with the list of all whiskys
   */
  parseWhiskysCSV = (allProfiles) => {
    return new Promise((resolve, reject) => {

      let allWhiskys = [];


      for (const whiskyData in whiskys) {
        let whisky = whiskys[whiskyData];

        // Attach distillery
        let distillery = allProfiles.find(profile => {
          return profile.id === whisky.id;
        });

        whisky.distillery = distillery.distillery;

        // Attach plot data
        let xy = xyData.find(e => {
          return e.Distillery === whisky.distillery
        })

        whisky.x = xy.x;
        whisky.y = xy.y;

        allWhiskys.push(whisky);
      }

      whiskyData.whiskys = [...allWhiskys]

      resolve(allWhiskys);
    })
  }

  /**
   * Returns an array of whiskys from given distillery
   * @param {string} distillery name
   */
  getDistilleryWhiskys(distillery, whiskys) {
    let whiskyList = [];
    if (whiskys === undefined) {
      console.log("no whikys provided");
      whiskyList = whiskyData.whiskys.filter(whisky => whisky.distillery === distillery);
    } else {
      console.log("whikys provided");
      whiskyList = whiskys.filter(whisky => whisky.distillery === distillery);
    }
    return whiskyList;
  }

  getWhiskyByCluster(cluster, page) {
    let whiskys = whiskyData.whiskys
      .filter((whisky) => {
        return whisky.cluster === cluster;
      })
      .filter((whisky) => {
        return whisky.cost === whiskyData.selectedCost
      })

    if (!page) page = 1;
    let paginated = this.Paginator(whiskys, page, 10);
    let data = paginated.data;

    whiskyData.paginated = { ...paginated }
    return data;
  }

  /**
   * navigate to next page
   */
  nextPage = (e) => {
    if (whiskyData.paginated.next_page !== null) {
      this.selectCluster(whiskyData.selectedCluster.cluster, whiskyData.paginated.next_page)
    }
  }

  /**
   * navigate to previous page
   */
  previousPage = (e) => {
    if (whiskyData.paginated.pre_page !== null) {
      this.selectCluster(whiskyData.selectedCluster.cluster, whiskyData.paginated.pre_page)
    }
  }

  /**
   * Prepare nodes and links from an object
   * @param {object} sankeyData 
   */
  processSankeyCSV(sankeyData) {
    return new Promise((resolve, reject) => {
      this.createNodes(sankeyData).then(nodes => {
        this.createLinks(sankeyData, nodes).then(links => {
          resolve({ nodes, links })
        });
      });
    })
  }

  /**
   * 
   * @param {object} whiskys 
   */
  createNodes(whiskys) {
    return new Promise((resolve, reject) => {

      let nodes = [];
      let currentClusters = [];

      // let sortCost = function (a, b) {
      //   return (a.cost > b.cost) ? 1 : ((a.cost < b.cost ? -1 : 0))
      // }

      // let sortRating = function (a, b) {
      //   return (a.rating > b.rating) ? 1 : ((a.rating < b.rating ? -1 : 0))
      // }

      let sortCluster = function (a, b) {
        if (
          b.name === "A" || a.name === "A" ||
          b.name === "B" || a.name === "B" ||
          b.name === "C" || a.name === "C" ||
          b.name === "E" || a.name === "E" ||
          b.name === "F" || a.name === "F" ||
          b.name === "G" || a.name === "G" ||
          b.name === "H" || a.name === "H" ||
          b.name === "I" || a.name === "I" ||
          b.name === "J" || a.name === "J"
        ) {
          console.log(a.name, b.name);
          return (a.name > b.name) ? 1 : ((a.name < b.name ? -1 : 0))
        }
      }

      let sortBoth = function (a, b) {
        if (a.cost === b.cost) {
          return (a.rating > b.rating) ? 1 : ((a.rating < b.rating ? -1 : 0))
        } else {
          return (a.cost > b.cost) ? 1 : ((a.cost < b.cost ? -1 : 0))
        }
      }

      // whiskys.sort(sortCluster);
      whiskys.sort(sortBoth);

      whiskys.forEach(row => {

        // push only unique clusters
        if (!nodes.find((e) => { return row.cluster === e.name })) {
          nodes.push({
            name: row.cluster,
            id: row.cluster
          })
          let c = clusters.find(cluster => {
            return cluster.cluster === row.cluster
          })
          currentClusters.push(c)
        }

        whiskyData.currentClusters = [...currentClusters]
        this.setState({
          currentClusters
        })

        // Whisky names are always unique, so push all the whiskys
        nodes.push({
          name: row.whisky,
          id: row.whisky,
          value: row.rating
        })

        // push only unique costs
        if (!nodes.find((e) => { return row.cost === e.name })) {
          nodes.push({
            name: row.cost,
            id: row.cost
          })
        }
      })

      nodes.sort(sortCluster);

      whiskyData.clusters = [...currentClusters]

      resolve(nodes);
    })

  }

  // Create the links between nodes
  createLinks(whiskys, nodes) {
    // Whisky, Cluster, Cost, Rating (needs to be cast to float)
    return new Promise((resolve, reject) => {
      let clusterLinks = [];
      let costLinks = [];

      // get index of link
      function getNodeIndex(node) {
        return nodes.findIndex((n) => {
          return n.name === node;
        })
      }

      let sortCluster = function (a, b) {
        return (a.Cluster < b.Cluster) ? 1 : ((a.Cluster > b.Cluster ? -1 : 0))
      }

      let sortCost = function (a, b) {
        return (a.cost > b.cost) ? 1 : ((a.cost < b.cost ? -1 : 0))
      }

      // Cluster -> Cost
      whiskys.forEach(row => {
        // Try to find the index of the 
        let index = clusterLinks.findIndex((link) => {
          return link.source === getNodeIndex(row.cluster) && link.target === getNodeIndex(row.cost)
        })
        // If the link already exists, increase the weight
        if (index !== -1) {
          clusterLinks[index].value++;
        } else {
          // Otherwise make a new link
          clusterLinks.push({
            source: getNodeIndex(row.cluster),
            target: getNodeIndex(row.cost),
            value: 1
          })
        }
      })

      // Cost -> Whisky
      whiskys.forEach(row => {
        // Try to find the index of the 
        let index = costLinks.findIndex((link) => {
          return link.source === getNodeIndex(row.cost) && link.target === getNodeIndex(row.whisky)
        })
        // If the link already exists, increase the weight
        if (index !== -1) {
          costLinks[index].value++;
        } else {
          // Otherwise make a new link
          clusterLinks.push({
            source: getNodeIndex(row.cost),
            target: getNodeIndex(row.whisky),
            value: 1
          })
        }
      })
      resolve([...clusterLinks, ...costLinks]);
    })
  }

  scrollToMyRef = () => {
    console.log("offtop", this.myRef.offsetTop)
    window.scrollTo(0, this.myRef.offsetTop)
  }

  Paginator = (items, page, per_page) => {
    let offset = (page - 1) * per_page,
      paginatedItems = items.slice(offset).slice(0, per_page),
      total_pages = Math.ceil(items.length / per_page);
    return {
      page: page,
      per_page: per_page,
      pre_page: page - 1 ? page - 1 : null,
      next_page: (total_pages > page) ? page + 1 : null,
      total: items.length,
      total_pages: total_pages,
      data: paginatedItems
    };
  }

  /**
   * Draws a sanky diagram 
   * @param {array} sankyData links and node objects inside the array
   */
  drawSankey(sankyData, barData) {

    const minChartHeight = 20;
    const maxChartHeight = 900;

    const minBarWidth = Math.min(...barData.map(o => o.rating), 10);
    const maxBarWidth = Math.max(...barData.map(o => o.rating), 0);

    let scale = d3.scale.linear()
      .range([30, 700])
      .domain([minBarWidth, maxBarWidth + .5]);

    const node = this.node;
    let svg = d3.select(node);

    // reset width
    d3.select(node)
      .attr("width", 1000);

    let scaleChartHeight = d3.scale.linear()
      .range([minChartHeight, maxChartHeight])
      .domain([0, 31]);

    d3.select(node)
      .attr("height", scaleChartHeight(barData.length));

    // reset chart
    svg.selectAll("*").remove();

    // create sanky
    let chart = new Sanky(svg);

    chart
      .name(label)
      .colorNodes(function (name, node) {
        return color(node, 1) || colors.fallback;
      })
      .colorLinks(function (link) {
        return color(link.source, 4) || color(link.target, 1) || colors.fallback;
      })
      .nodeWidth(15)
      .nodePadding(10)
      .spread(true)
      .iterations(0)
      .draw(sankyData);

    function label(node) {
      return node.name.replace(/\s*\(.*?\)$/, '');
    }

    function color(node, depth) {
      var id = node.id.replace(/(_score)?(_\d+)?$/, '');
      if (colors[id]) {
        return colors[id];
      } else if (depth > 0 && node.targetLinks && node.targetLinks.length === 1) {
        return color(node.targetLinks[0].source, depth - 1);
      } else {
        return null;
      }
    }

    // fix colors on sanky
    d3.selectAll("path")
      .style('fill', 'transparent')
      .style('stroke-opacity', '0.3')
      .filter((item) => {
        if (item) {
          return item.source.x > 400;
        }
        return false;
      })
      .filter((item, index) => {
        return true;
      })
      .attr("id", "asf")

    d3.selectAll("g")
      .select('rect')
      .style('stroke', 'transparent')

    // set up bar graph
    d3.selectAll("g")
      .filter((item) => {
        if (item) {
          return item.x > 700;
        }
        return false;
      })
      .filter((item, index) => {
        return true;
      })
      .select("rect")
      .attr("width", 100)

    // set width to allow for bars on right side
    d3
      .select(node)
      .attr("width", 1500);

    barData.forEach(whisky => {
      let rating = whisky.rating;
      let id = whisky.whisky;
      d3.select('[data-node-id="' + id + '"')
        .select("rect")
        .attr("width", scale(rating))
        .attr("fill-opacity", .8)

      d3.select('[data-node-id="' + id + '"')
        .select("text")
        .each((d, i) => {
        })
        .text(whisky.whisky)// + " " + rating + "/10")

      d3.select('[data-node-id="' + id + '"')
        .append("rect")
        .attr("width", 55)
        .attr("height", 20)
        .attr("fill-opacity", .3)
        .attr("style", "fill: rgb(255, 255, 255);")

      d3.select('[data-node-id="' + id + '"')
        .append("text")
        .attr("y", 10)
        .attr("x", 10)
        .attr("dy", ".35em")
        .text(rating);
    })

    d3.selectAll("text")
      .attr("font-size", "12pt")
      .attr("font-family", "Roboto, sans-serif")
  }

  drawScatter(dataset) {
    let that = this;
    var w = window.innerWidth,
      h = window.innerWidth,
      margin = { top: 0, right: 0, bottom: 0, left: 0 },
      radius = 10;


    let svg = d3.select(this.scatter).attr({
      width: w,
      height: h
    });

    svg.selectAll("*").remove();

    // We're passing in a function in d3.max to tell it what we're maxing (x value)
    var xScale = d3.scale.linear()
      .domain([0, d3.max(dataset, function (d) { return d.x + 10; })])
      .range([margin.left, w - margin.right]);  // Set margins for x specific

    // We're passing in a function in d3.max to tell it what we're maxing (y value)
    var yScale = d3.scale.linear()
      .domain([0, d3.max(dataset, function (d) { return d.y + 10; })])
      .range([margin.top, h - margin.bottom]);  // Set margins for y specific

    // Add a X and Y Axis (Note: orient means the direction that ticks go, not position)
    // var xAxis = d3.svg.axis().scale(xScale).orient("top");
    // var yAxis = d3.svg.axis().scale(yScale).orient("left");

    var circleAttrs = {
      cx: function (d) { return xScale(d.x); },
      cy: function (d) { return yScale(d.y); },
      r: radius
    };

    // Adds X-Axis as a 'g' element
    // svg.append("g").attr({
    //     "class": "axis",  // Give class so we can style it
    //     transform: "translate(" + [0, margin.top] + ")"  // Translate just moves it down into position (or will be on top)
    // }).call(xAxis);  // Call the xAxis function on the group

    // // Adds Y-Axis as a 'g' element
    // svg.append("g").attr({
    //     "class": "axis",
    //     transform: "translate(" + [margin.left, 0] + ")"
    // }).call(yAxis);  // Call the yAxis function on the group

    svg.selectAll("circle")
      .data(dataset)
      .enter()
      .append("circle")
      .attr(circleAttrs)  // Get attributes from circleAttrs var
      .on("mouseover", handleMouseOver)
      .on("mouseout", handleMouseOut)
      .on("click", handleClick);

    function handleClick(d, i) {
      console.log(d.Distillery);
      that.selectDistillery(d.Distillery);
    }

    // Create Event Handlers for mouse
    function handleMouseOver(d, i) {  // Add interactivity

      // Use D3 to select element, change color and size
      d3.select(this).attr({
        fill: "orange",
        r: radius * 2
      });

      // Specify where to put label of text
      svg.append("text")
        .attr({
          id: "t" + d.Distillery.split(" ").join("-"),  // Create an id for text so we can select it later for removing on mouseout
          x: function () { return xScale(d.x) - 30; },
          y: function () { return yScale(d.y) - 15; },
        })
        .text(function () {
          return d.Distillery;  // Value of the text
        });
    }

    function handleMouseOut(d, i) {
      // Use D3 to select element, change color back to normal
      d3.select(this).attr({
        fill: "black",
        r: radius
      });

      // Select text by id and then remove
      d3.select("#t" + d.Distillery.split(" ").join("-")).remove();  // Remove text location
    }

  }

  processRadarData = (distillery) => {
    return [[
      { axis: "BODY", value: distillery.body },
      { axis: "WINEY", value: distillery.winey },
      { axis: "SPICY", value: distillery.spicy },
      { axis: "TOBACCO", value: distillery.tobacco },
      { axis: "SMOKY", value: distillery.smoky },
      { axis: "MEDICINAL", value: distillery.medicinal },
      { axis: "SWEETNESS", value: distillery.sweetness },
      { axis: "HONEY", value: distillery.honey },
      { axis: "FRUITY", value: distillery.fruity },
      { axis: "NUTTY", value: distillery.nutty },
      { axis: "MALTY", value: distillery.malty },
      { axis: "FLORAL", value: distillery.floral }
    ]]
  }

  drawRadar(d) {
    var w = 300,
      h = 350;
    //Options for the Radar chart, other than default
    var mycfg = {
      w: w,
      h: h,
      ExtraWidthX: 300
    }

    ////////////////////////////////////////////
    /////////// Initiate legend ////////////////
    ////////////////////////////////////////////

    var svg = d3.select(this.radar)
      .selectAll('svg')
      .append('svg')
      .attr("width", w + 300)
      .attr("height", h + 10)

    var RadarChart = {
      draw: function (id, d, options) {
        var cfg = {
          radius: 5,
          w: 300,
          h: 300,
          factor: 1,
          factorLegend: .85,
          levels: 3,
          maxValue: 0,
          radians: 2 * Math.PI,
          opacityArea: 0.5,
          ToRight: 5,
          TranslateX: 80,
          TranslateY: 30,
          ExtraWidthX: 100,
          ExtraWidthY: 100,
          color: d3.scale.category10()
        };

        if ('undefined' !== typeof options) {
          for (var i in options) {
            if ('undefined' !== typeof options[i]) {
              cfg[i] = options[i];
            }
          }
        }
        cfg.maxValue = Math.max(cfg.maxValue, d3.max(d, function (i) { return d3.max(i.map(function (o) { return o.value; })) }));
        var allAxis = (d[0].map(function (i, j) { return i.axis }));
        var total = allAxis.length;
        // var radius = cfg.factor * Math.min(cfg.w / 2, cfg.h / 2);
        // var Format = d3.format('%');
        d3.select(id).select("svg").remove();

        var g = d3.select(id)
          .append("svg")
          .attr("width", cfg.w + cfg.ExtraWidthX)
          .attr("height", cfg.h + cfg.ExtraWidthY)
          .append("g")
          .attr("transform", "translate(" + 80 + "," + 50 + ")");

        let series = 0;

        var axis = g.selectAll(".axis")
          .data(allAxis)
          .enter()
          .append("g")
          .attr("class", "axis");

        axis.append("text")
          .attr("class", "legend")
          .text(function (d) { return d })
          .style("font-family", "sans-serif")
          .style("font-size", "15px")
          .attr("text-anchor", "middle")
          .attr("dy", "1.5em")
          .attr("transform", function (d, i) { return "translate(0, -10)" })
          .attr("y", function (d, i) { return cfg.w / 2 * (1 - cfg.factorLegend * Math.sin(i * cfg.radians / total)) - 60 * Math.sin(i * cfg.radians / total); })
          .attr("x", function (d, i) { return cfg.h / 2 * (1 - Math.cos(i * cfg.radians / total)) - 20 * Math.cos(i * cfg.radians / total); });

        d.forEach(function (y, x) {
          let dataValues = [];
          g.selectAll(".nodes")
            .data(y, function (j, i) {
              dataValues.push([
                cfg.w / 2 * (1 - (parseFloat(Math.max(j.value, 0)) / cfg.maxValue) * cfg.factor * Math.sin(i * cfg.radians / total)),
                cfg.h / 2 * (1 - (parseFloat(Math.max(j.value, 0)) / cfg.maxValue) * cfg.factor * Math.cos(i * cfg.radians / total))
              ]);
            });
          dataValues.push(dataValues[0]);
          g.selectAll(".area")
            .data([dataValues])
            .enter()
            .append("polygon")
            .attr("class", "radar-chart-serie" + series)
            .style("stroke-width", "0px")
            .style("stroke", cfg.color(series))
            .attr("points", function (d) {
              var str = "";
              for (var pti = 0; pti < d.length; pti++) {
                str = str + d[pti][0] + "," + d[pti][1] + " ";
              }
              return str;
            })
            .style("fill", function (j, i) { return cfg.color(series) })
            .style("fill-opacity", cfg.opacityArea)

          series++;
        });
        series = 0;

        d.forEach(function (y, x) {
          g.selectAll(".nodes")
            .data(y).enter()
          series++;
        });
      }
    };

    //Call function to draw the Radar chart
    //Will expect that data is in %'s
    RadarChart.draw(this.radar, d, mycfg);
  }

  createSankey(distilleryName, allWhiskys) {
    let whiskys = this.getDistilleryWhiskys(distilleryName, allWhiskys);
    this.processSankeyCSV(whiskys)
      .then(sankeyData => {
        this.drawSankey(sankeyData, whiskys);
        this.TableRows();
      });
  }

  handleSelectDistillery = (e) => {
    let distillery = whiskyData.distilleryProfiles.find(profile => {
      return profile.distillery === e.target.value
    })

    whiskyData.scroll = true;
    whiskyData.selectedDistillery = e.target.value;
    whiskyData.selectedDistilleryData = { ...distillery }

    console.log("dis", distillery);

    this.createSankey(e.target.value);

    let radarData = this.processRadarData(distillery);
    this.drawRadar(radarData);
  }

  handleSelectCluster = (e) => {
    let cluster = e.target.value;
    this.selectCluster(cluster);
  }

  selectCluster = (cluster, page) => {

    if (!page) {
      whiskyData.scroll = true;
    }

    let selected = clusters.find(c => {
      return c.cluster === cluster
    })

    whiskyData.selectCluster = selected

    let whiskys = this.getWhiskyByCluster(selected.cluster, page);
    this.processSankeyCSV(whiskys)
      .then(sankeyData => this.drawSankey(sankeyData, whiskys));
  }

  selectDistillery(distilleryName) {
    let whiskys = this.getDistilleryWhiskys(distilleryName);
    this.processSankeyCSV(whiskys)
      .then(sankeyData => this.drawSankey(sankeyData, whiskys));
  }

  handleSelectCost = (e) => {
    let cost = e.target.value;
    whiskyData.selectedCost = cost
    this.selectCluster(whiskyData.selectedCluster.cluster, cost)

    let selected = whiskyData.selectedCluster;
    let whiskys = this.getWhiskyByCluster(selected.cluster, 0);
    this.processSankeyCSV(whiskys)
      .then(sankeyData => this.drawSankey(sankeyData, whiskys));
  }

  TableRows = () => {
    whiskyData.currentClusters.map(cluster => {
      console.log("cl", cluster);
      return <tr>
        <th scope="row">{cluster.cluster}</th>
        <td >{cluster.description}</td>
      </tr>
    })
  }

  handleCheckboxChange = event => {
    whiskyData.checked = !event.target.checked;
    if (!event.target.checked) {
      this.selectCluster(whiskyData.selectedCluster.cluster);
    } else {
      this.selectDistillery(whiskyData.selectedDistillery)
    }
  }
  render() {

    let Radar;
    if (whiskyData.checked) {
      Radar = <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }}>
        <div ref={radar => this.radar = radar}></div>
      </div>
    } else {
      Radar = <div></div>
    }

    let DistilleryList;
    if (whiskyData.checked) {
      DistilleryList =
        <form className="form-inline">
          <div className="container form-group">
            <label style={{
              fontFamily: "'Roboto', sans-serif",
              fontSize: 36,
              fontWeight: 300,
            }} for="exampleFormControlSelect1">you're looking at the distillery </label>
            <select
              value={whiskyData.selectedDistillery}
              onChange={this.handleSelectDistillery}
              className="form-control form-control-lg"
              id="exampleFormControlSelect1">
              {whiskyData.distilleryProfiles.map(whisky => {
                return <option value={whisky.distillery} key={whisky.distillery}>{whisky.distillery}</option>
              })}
            </select>
          </div>
        </form>
    } else {
      DistilleryList = <div></div>
    }

    let PickCluster;

    if (!whiskyData.checked) {
      PickCluster =
        <div className="container mb-3">
          <div className="input-group">

            <select value={whiskyData.selectedCost} onChange={this.handleSelectCost} className="custom-select mr-2" id="exampleFormControlSelect3">
              {whiskyData.costs.map(cost => {
                return <option value={cost} key={cost}>{cost}</option>
              })}
            </select>
            <div className="input-group-append">
              <select onChange={this.handleSelectCluster} className="custom-select" id="exampleFormControlSelect2">
                {whiskyData.profiles.map(cluster => {
                  return <option value={cluster.cluster} key={cluster.cluster}>{cluster.description}</option>
                })}
              </select>
            </div>
          </div>
          <div className="btn-group mt-3" role="group" aria-label="Basic example">
            <button disabled={whiskyData.paginated.pre_page ? false : true} onClick={this.previousPage} type="button" class="btn btn-primary">previous page {whiskyData.paginated.pre_page}</button>
            <button disabled={whiskyData.paginated.next_page ? false : true} onClick={this.nextPage} type="button" class="btn btn-primary">next page {whiskyData.paginated.next_page}</button>
          </div>
        </div>
    } else {
      PickCluster = <div></div>
    }

    let ClusterType;

    if (!whiskyData.checked) {
      ClusterType = <div
        className="container"
        style={{
          fontFamily: "'Roboto', sans-serif",
          fontSize: 36,
          fontWeight: 300,
          marginTop: 10,
          marginBottom: 30
        }}>
        you're looking at whiskys that are {whiskyData.selectedCluster.description.toLowerCase()}
      </div>
    } else {
      ClusterType = <div></div>
    }

    return (
      <div
        style={{
          height: "100%",
        }}>

        <div className="hero">
          <div className="container">
            <h1
              style={{
                fontFamily: "'Roboto', sans-serif",
                fontSize: 150,
                margin: 0,
                marginBottom: 92,
                fontWeight: 700
              }}>
              whiskey comparisons <span style={{
                fontWeight: 300,
                fontSize: 40,
                color: 'darkblue'
              }}>beta</span>
            </h1>
            <h1
              style={{
                fontFamily: "'Roboto', sans-serif",
                fontSize: "24pt",
                fontWeight: 300,
              }}>Discover your new favorite whiskeys</h1>
          </div>

          {/* <img src={require('./whiskybackgroudn.png')} alt=''></img> */}
        </div>
        <div
          ref={this.myRef}
          className="container"
          style={{
            fontFamily: "'Roboto', sans-serif",
            fontSize: "18pt",
            fontWeight: 400
          }}>
          search by flavor profiles
          <label className="switch ml-3">
            <input onChange={this.handleCheckboxChange} checked={!whiskyData.checked} type="checkbox"></input>
            <span className="slider round"></span>
          </label>
        </div>
        {ClusterType}

        {/* WHISKY LIST */}
        {DistilleryList}
        {PickCluster}
        <link href="https://fonts.googleapis.com/css?family=Abril+Fatface+Rubik+Roboto:100,300,400,500,700+Italianno" rel="stylesheet"></link>
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous"></link>

        {/* scroll down */}
        {/* <div
          className="text-black-50"
          style={{
            position: "absolute",
            left: 1130,
            top: 500,
            transform: "rotate(90deg)",
            // color: "white",
            fontSize: 30,
            fontFamily: "'Rubik', sans-serif"
          }}>scroll down</div> */}

        {/* SANKY */}
        <div className="sankey">
          <svg
            style={{
              marginLeft: 20,
              marginBottom: 50,
              marginTop: 60,
            }}
            width={1000}
            height={500}
            ref={node => this.node = node}>
          </svg>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}>

          <table className="table w-50 ml-4">
            <tbody>
              {
                this.state.currentClusters.map(cluster => {
                  console.log("cl", cluster);
                  return <tr>
                    <th scope="row">{cluster.cluster}</th>
                    <td >{cluster.description}</td>
                  </tr>
                })
              }
            </tbody>
          </table>
          {/* {Radar} */}
        </div>

        {/* SCATTER */}
        {/* <svg
          style={{
            marginTop: -50,
          }}
          width={1500}
          height={500}
          ref={scatter => this.scatter = scatter}>
        </svg> */}
        <footer className="footer"></footer>
      </div>
    );
  }
}

export default App;