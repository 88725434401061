export default [
    {
        "label": "Aberfeldy",
        "Distillery": "Aberfeldy",
        "y": 8.4,
        "x": 11.2
    },
    {
        "label": "Aberlour",
        "Distillery": "Aberlour",
        "y": 6.666666667,
        "x": 10
    },
    {
        "label": "AnCnoc",
        "Distillery": "AnCnoc",
        "y": 8.666666667,
        "x": 5.4
    },
    {
        "label": "Ardbeg",
        "Distillery": "Ardbeg",
        "y": 17.46666667,
        "x": 16.4
    },
    {
        "label": "Ardmore",
        "Distillery": "Ardmore",
        "y": 8.666666667,
        "x": 12.53333333
    },
    {
        "label": "Auchentoshan",
        "Distillery": "Auchentoshan",
        "y": 5.466666667,
        "x": 7.4
    },
    {
        "label": "Auchroisk",
        "Distillery": "Auchroisk",
        "y": 8.533333333,
        "x": 10.06666667
    },
    {
        "label": "Aultmore",
        "Distillery": "Aultmore",
        "y": 7.266666667,
        "x": 9.266666667
    },
    {
        "label": "Balblair",
        "Distillery": "Balblair",
        "y": 11.66666667,
        "x": 11.4
    },
    {
        "label": "BenRiach",
        "Distillery": "BenRiach",
        "y": 9.066666667,
        "x": 9.2
    },
    {
        "label": "Benrinnes",
        "Distillery": "Benrinnes",
        "y": 7.6,
        "x": 10.13333333
    },
    {
        "label": "Benromach",
        "Distillery": "Benromach",
        "y": 8.466666667,
        "x": 11.4
    },
    {
        "label": "Bladnoch",
        "Distillery": "Bladnoch",
        "y": 8.533333333,
        "x": 11.06666667
    },
    {
        "label": "Blair Athol",
        "Distillery": "Blair Athol",
        "y": 8,
        "x": 13
    },
    {
        "label": "Bowmore",
        "Distillery": "Bowmore",
        "y": 12.2,
        "x": 12.66666667
    },
    {
        "label": "Bruichladdich",
        "Distillery": "Bruichladdich",
        "y": 10.66666667,
        "x": 11.6
    },
    {
        "label": "Bunnahabhain",
        "Distillery": "Bunnahabhain",
        "y": 8.533333333,
        "x": 10.06666667
    },
    {
        "label": "Caol Ila",
        "Distillery": "Caol Ila",
        "y": 15.66666667,
        "x": 15.06666667
    },
    {
        "label": "Cardhu",
        "Distillery": "Cardhu",
        "y": 7.133333333,
        "x": 8.266666667
    },
    {
        "label": "Clynelish",
        "Distillery": "Clynelish",
        "y": 17.26666667,
        "x": 13.4
    },
    {
        "label": "Dalmore",
        "Distillery": "Dalmore",
        "y": 11.46666667,
        "x": 13.26666667
    },
    {
        "label": "Dalwhinnie",
        "Distillery": "Dalwhinnie",
        "y": 9.4,
        "x": 9.666666667
    },
    {
        "label": "Deanston",
        "Distillery": "Deanston",
        "y": 8.666666667,
        "x": 10.13333333
    },
    {
        "label": "Dufftown",
        "Distillery": "Dufftown",
        "y": 9.666666667,
        "x": 9.133333333
    },
    {
        "label": "Edradour",
        "Distillery": "Edradour",
        "y": 5.066666667,
        "x": 9.066666667
    },
    {
        "label": "Glen Deveron Macduff",
        "Distillery": "Glen Deveron Macduff",
        "y": 12.53333333,
        "x": 13.46666667
    },
    {
        "label": "Glen Elgin",
        "Distillery": "Glen Elgin",
        "y": 8.066666667,
        "x": 9.2
    },
    {
        "label": "Glen Garioch",
        "Distillery": "Glen Garioch",
        "y": 11.13333333,
        "x": 15.4
    },
    {
        "label": "Glen Grant",
        "Distillery": "Glen Grant",
        "y": 8.066666667,
        "x": 9.066666667
    },
    {
        "label": "Glen Moray",
        "Distillery": "Glen Moray",
        "y": 5.4,
        "x": 11.53333333
    },
    {
        "label": "Glen Ord",
        "Distillery": "Glen Ord",
        "y": 8.133333333,
        "x": 13.53333333
    },
    {
        "label": "Glen Scotia",
        "Distillery": "Glen Scotia",
        "y": 11.13333333,
        "x": 11.53333333
    },
    {
        "label": "Glen Spey",
        "Distillery": "Glen Spey",
        "y": 8.2,
        "x": 12.06666667
    },
    {
        "label": "Glendronach",
        "Distillery": "Glendronach",
        "y": 10.33333333,
        "x": 15.26666667
    },
    {
        "label": "Glendullan",
        "Distillery": "Glendullan",
        "y": 8.466666667,
        "x": 11.66666667
    },
    {
        "label": "Glenfarclas",
        "Distillery": "Glenfarclas",
        "y": 6.266666667,
        "x": 12.2
    },
    {
        "label": "Glenfiddich",
        "Distillery": "Glenfiddich",
        "y": 9.533333333,
        "x": 8.466666667
    },
    {
        "label": "Glengoyne",
        "Distillery": "Glengoyne",
        "y": 6.466666667,
        "x": 9
    },
    {
        "label": "Glenkinchie",
        "Distillery": "Glenkinchie",
        "y": 9.133333333,
        "x": 10.66666667
    },
    {
        "label": "Glenlivet",
        "Distillery": "Glenlivet",
        "y": 7.266666667,
        "x": 11.13333333
    },
    {
        "label": "Glenlossie",
        "Distillery": "Glenlossie",
        "y": 8.466666667,
        "x": 10.4
    },
    {
        "label": "Glenmorangie",
        "Distillery": "Glenmorangie",
        "y": 9.466666667,
        "x": 11.66666667
    },
    {
        "label": "Glenrothes",
        "Distillery": "Glenrothes",
        "y": 10.53333333,
        "x": 11.2
    },
    {
        "label": "Glenturret",
        "Distillery": "Glenturret",
        "y": 7.333333333,
        "x": 12.6
    },
    {
        "label": "Highland Park",
        "Distillery": "Highland Park",
        "y": 12.13333333,
        "x": 11.06666667
    },
    {
        "label": "Inchgower",
        "Distillery": "Inchgower",
        "y": 9.533333333,
        "x": 9.2
    },
    {
        "label": "Isle of Jura",
        "Distillery": "Isle of Jura",
        "y": 12.26666667,
        "x": 12.13333333
    },
    {
        "label": "Lagavulin",
        "Distillery": "Lagavulin",
        "y": 19.66666667,
        "x": 17.4
    },
    {
        "label": "Laphroaig",
        "Distillery": "Laphroaig",
        "y": 19.2,
        "x": 15.53333333
    },
    {
        "label": "Linkwood",
        "Distillery": "Linkwood",
        "y": 10.2,
        "x": 10.66666667
    },
    {
        "label": "Loch Lomond",
        "Distillery": "Loch Lomond",
        "y": 9,
        "x": 11.4
    },
    {
        "label": "Longmorn",
        "Distillery": "Longmorn",
        "y": 4.533333333,
        "x": 12.66666667
    },
    {
        "label": "Macallan",
        "Distillery": "Macallan",
        "y": 8.6,
        "x": 13.6
    },
    {
        "label": "Mannochmore",
        "Distillery": "Mannochmore",
        "y": 8.333333333,
        "x": 12.06666667
    },
    {
        "label": "Miltonduff",
        "Distillery": "Miltonduff",
        "y": 8.466666667,
        "x": 8.666666667
    },
    {
        "label": "Mortlach",
        "Distillery": "Mortlach",
        "y": 9.066666667,
        "x": 15
    },
    {
        "label": "Oban",
        "Distillery": "Oban",
        "y": 12.06666667,
        "x": 12.26666667
    },
    {
        "label": "Old Pulteney",
        "Distillery": "Old Pulteney",
        "y": 11.13333333,
        "x": 13.46666667
    },
    {
        "label": "Royal Brackla",
        "Distillery": "Royal Brackla",
        "y": 12.46666667,
        "x": 10.53333333
    },
    {
        "label": "Royal Lochnagar",
        "Distillery": "Royal Lochnagar",
        "y": 9.4,
        "x": 12.26666667
    },
    {
        "label": "Scapa",
        "Distillery": "Scapa",
        "y": 8.133333333,
        "x": 10.26666667
    },
    {
        "label": "Speyburn",
        "Distillery": "Speyburn",
        "y": 9.533333333,
        "x": 8.066666667
    },
    {
        "label": "Speyside",
        "Distillery": "Speyside",
        "y": 7.333333333,
        "x": 10.33333333
    },
    {
        "label": "Springbank",
        "Distillery": "Springbank",
        "y": 12.46666667,
        "x": 13.66666667
    },
    {
        "label": "Strathisla",
        "Distillery": "Strathisla",
        "y": 5.466666667,
        "x": 11
    },
    {
        "label": "Strathmill",
        "Distillery": "Strathmill",
        "y": 8.066666667,
        "x": 10.26666667
    },
    {
        "label": "Talisker",
        "Distillery": "Talisker",
        "y": 15.26666667,
        "x": 14.66666667
    },
    {
        "label": "Tamdhu",
        "Distillery": "Tamdhu",
        "y": 8.133333333,
        "x": 8.4
    },
    {
        "label": "Tamnavulin",
        "Distillery": "Tamnavulin",
        "y": 8.666666667,
        "x": 10.6
    },
    {
        "label": "Teaninich",
        "Distillery": "Teaninich",
        "y": 13,
        "x": 12.6
    },
    {
        "label": "Tobermory",
        "Distillery": "Tobermory",
        "y": 8.666666667,
        "x": 9.6
    },
    {
        "label": "Tomatin",
        "Distillery": "Tomatin",
        "y": 10.4,
        "x": 12.33333333
    },
    {
        "label": "Tomintoul",
        "Distillery": "Tomintoul",
        "y": 8.666666667,
        "x": 9
    },
    {
        "label": "Tormore",
        "Distillery": "Tormore",
        "y": 10.6,
        "x": 12.33333333
    },
    {
        "label": "Tullibardine",
        "Distillery": "Tullibardine",
        "y": 9.066666667,
        "x": 12.6
    }
]