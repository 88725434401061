export const clusters = [
    {
        "cluster": "A",
        "description": "Full-bodied, sweet, pronounced sherry – with fruity, honey and spicy notes"
    },
    {
        "cluster": "B",
        "description": "Full-bodied, sweet, pronounced sherry – with fruity, floral and malty notes, some honey and spicy notes may be evident"
    },
    {
        "cluster": "C",
        "description": "Full-bodied, sweet, pronounced sherry – with fruity, floral, nutty, and spicy notes, some smoky notes may be evident"
    },
    {
        "cluster": "E",
        "description": "Medium-bodied, medium-sweet – with fruity, honey, malty and winey notes, some smoky and spicy notes may be evident"
    },
    {
        "cluster": "F",
        "description": "Full-bodied, sweet and malty – with fruity, spicy, and smoky notes"
    },
    {
        "cluster": "G",
        "description": "Light-bodied, sweet, apéritif-style – with honey, floral, fruity and spicy notes, but rarely any smoky notes "
    },
    {
        "cluster": "H",
        "description": "Very light-bodied, sweet, apéritif-style – with malty, fruity and floral notes"
    },
    {
        "cluster": "I",
        "description": "Medium-bodied, medium-sweet, quite smoky – with some medicinal notes and spicy, fruity and nutty notes "
    },
    {
        "cluster": "J",
        "description": "Full-bodied, dry, very smoky, pungent – with medicinal notes and some  spicy, malty and fruity notes"
    }
]

export const costs = [
    '$',
    '$$',
    '$$$',
    '$$$$',
    '$$$$$',
    '$$$$$+'
]

export const colors = {
    '$': '#FE9C18',
    '$$': '#DD831A',
    '$$$': '#BC691C',
    '$$$$': '#9D511E',
    '$$$$$': '#7D3920',
    '$$$$$+': '#632522',
    'A': '#97BDCC',
    'B': '#438199',
    'C': '#97BDCC',
    'E': '#438199',
    'F': '#438199',
    'G': '#97BDCC',
    'H': '#438199',
    'I': '#97BDCC',
    'J': '#438199',
    'fallback': '#9f9fa3'
};