export default [
  {
    "id": 1,
    "whisky": "Aberfeldy 12yo",
    "rating": 8.14,
    "STDEV": 0.33,
    "#": 20,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 1,
    "whisky": "Aberfeldy 16yo",
    "rating": 8.47,
    "STDEV": 0.21,
    "#": 4,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 1,
    "whisky": "Aberfeldy 18yo",
    "rating": 8.56,
    "STDEV": 0.25,
    "#": 8,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 1,
    "whisky": "Aberfeldy 21yo",
    "rating": 8.78,
    "STDEV": 0.19,
    "#": 10,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour 10yo",
    "rating": 8.23,
    "STDEV": 0.37,
    "#": 16,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour 12yo Double Cask Matured",
    "rating": 8.38,
    "STDEV": 0.21,
    "#": 18,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour 12yo Non-Chill-Filtered",
    "rating": 8.81,
    "STDEV": 0.22,
    "#": 13,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour 16yo Double Cask Matured",
    "rating": 8.68,
    "STDEV": 0.17,
    "#": 14,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour 18yo",
    "rating": 8.74,
    "STDEV": 0.27,
    "#": 14,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh all batches",
    "rating": 8.93,
    "STDEV": 0.15,
    "#": 25,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 30",
    "rating": 8.94,
    "STDEV": 0.17,
    "#": 6,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 32",
    "rating": 8.82,
    "STDEV": 0.7,
    "#": 4,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 33",
    "rating": 9.11,
    "STDEV": 0.13,
    "#": 5,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 34",
    "rating": 8.86,
    "STDEV": 0.29,
    "#": 5,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 35",
    "rating": 8.99,
    "STDEV": 0.23,
    "#": 5,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 36",
    "rating": 8.97,
    "STDEV": 0.49,
    "#": 4,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 37",
    "rating": 9.11,
    "STDEV": 0.09,
    "#": 3,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 38",
    "rating": 8.66,
    "STDEV": 0.19,
    "#": 3,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 39",
    "rating": 9.02,
    "STDEV": 0.21,
    "#": 8,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 40",
    "rating": 9.08,
    "STDEV": 0.13,
    "#": 4,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 42",
    "rating": 8.9,
    "STDEV": 0.26,
    "#": 4,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 45",
    "rating": 8.98,
    "STDEV": 0.23,
    "#": 7,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 46",
    "rating": 8.91,
    "STDEV": 0.09,
    "#": 4,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 47",
    "rating": 8.93,
    "STDEV": 0.33,
    "#": 10,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 48",
    "rating": 8.77,
    "STDEV": 0.51,
    "#": 4,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 49",
    "rating": 9.08,
    "STDEV": 0.21,
    "#": 9,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 50",
    "rating": 8.73,
    "STDEV": 0.34,
    "#": 7,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 52",
    "rating": 9.07,
    "STDEV": 0.26,
    "#": 4,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 53",
    "rating": 9.04,
    "STDEV": 0.11,
    "#": 4,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 54",
    "rating": 8.94,
    "STDEV": 0.16,
    "#": 6,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 57",
    "rating": 8.6,
    "STDEV": 0.5,
    "#": 5,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 58",
    "rating": 9.03,
    "STDEV": 0.15,
    "#": 6,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 59",
    "rating": 8.81,
    "STDEV": 0.21,
    "#": 4,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour A'Bunadh Batch 61",
    "rating": 8.81,
    "STDEV": 0.19,
    "#": 4,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 2,
    "whisky": "Aberlour Casg Annamh",
    "rating": 8.55,
    "STDEV": 0.29,
    "#": 7,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 3,
    "whisky": "AnCnoc 12yo",
    "rating": 8.6,
    "STDEV": 0.32,
    "#": 20,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 3,
    "whisky": "AnCnoc 16yo",
    "rating": 8.7,
    "STDEV": 0.32,
    "#": 15,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 3,
    "whisky": "AnCnoc 18yo",
    "rating": 8.67,
    "STDEV": 0.33,
    "#": 10,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 3,
    "whisky": "AnCnoc 1975",
    "rating": 8.85,
    "STDEV": 0.56,
    "#": 4,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 3,
    "whisky": "AnCnoc 2000",
    "rating": 8.61,
    "STDEV": 0.19,
    "#": 4,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 3,
    "whisky": "AnCnoc 22yo",
    "rating": 8.76,
    "STDEV": 0.31,
    "#": 10,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 3,
    "whisky": "AnCnoc Cutter",
    "rating": 8.97,
    "STDEV": 0.47,
    "#": 9,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 3,
    "whisky": "AnCnoc Flaughter",
    "rating": 8.57,
    "STDEV": 0.32,
    "#": 11,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 3,
    "whisky": "AnCnoc Peter Arkle all releases",
    "rating": 8.46,
    "STDEV": 0.3,
    "#": 9,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 3,
    "whisky": "AnCnoc Rutter",
    "rating": 8.87,
    "STDEV": 0.3,
    "#": 8,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 4,
    "whisky": "Ardbeg 10yo",
    "rating": 8.89,
    "STDEV": 0.35,
    "#": 25,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 4,
    "whisky": "Ardbeg 17yo",
    "rating": 8.95,
    "STDEV": 0.32,
    "#": 8,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 4,
    "whisky": "Ardbeg 21yo",
    "rating": 9.13,
    "STDEV": 0.27,
    "#": 11,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 4,
    "whisky": "Ardbeg 23yo Twenty Something",
    "rating": 9.28,
    "STDEV": 0.17,
    "#": 6,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 4,
    "whisky": "Ardbeg Alligator",
    "rating": 9.02,
    "STDEV": 0.18,
    "#": 15,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 4,
    "whisky": "Ardbeg An Oa",
    "rating": 8.82,
    "STDEV": 0.27,
    "#": 14,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 4,
    "whisky": "Ardbeg Ardbog",
    "rating": 9.02,
    "STDEV": 0.44,
    "#": 20,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 4,
    "whisky": "Ardbeg Auriverdes",
    "rating": 8.83,
    "STDEV": 0.37,
    "#": 19,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 4,
    "whisky": "Ardbeg Corryvreckan",
    "rating": 9.04,
    "STDEV": 0.32,
    "#": 23,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 4,
    "whisky": "Ardbeg Dark Cove",
    "rating": 8.86,
    "STDEV": 0.31,
    "#": 18,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 4,
    "whisky": "Ardbeg Galileo",
    "rating": 8.59,
    "STDEV": 0.6,
    "#": 17,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 4,
    "whisky": "Ardbeg Grooves",
    "rating": 8.84,
    "STDEV": 0.2,
    "#": 12,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 4,
    "whisky": "Ardbeg Kelpie",
    "rating": 8.8,
    "STDEV": 0.14,
    "#": 10,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 4,
    "whisky": "Ardbeg Perpetuum",
    "rating": 8.8,
    "STDEV": 0.43,
    "#": 17,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 4,
    "whisky": "Ardbeg Supernova 2014",
    "rating": 9.07,
    "STDEV": 0.31,
    "#": 15,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 4,
    "whisky": "Ardbeg Supernova 2015",
    "rating": 8.99,
    "STDEV": 0.32,
    "#": 7,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 4,
    "whisky": "Ardbeg Uigeadail",
    "rating": 9.17,
    "STDEV": 0.39,
    "#": 24,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 5,
    "whisky": "Ardmore Traditional Cask",
    "rating": 8.51,
    "STDEV": 0.21,
    "#": 22,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 6,
    "whisky": "Auchentoshan 10yo",
    "rating": 7.77,
    "STDEV": 0.35,
    "#": 10,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 6,
    "whisky": "Auchentoshan 12yo",
    "rating": 8.27,
    "STDEV": 0.26,
    "#": 23,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 6,
    "whisky": "Auchentoshan 18yo",
    "rating": 8.37,
    "STDEV": 0.37,
    "#": 16,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 6,
    "whisky": "Auchentoshan 21yo",
    "rating": 8.8,
    "STDEV": 0.26,
    "#": 12,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 6,
    "whisky": "Auchentoshan American Oak",
    "rating": 7.75,
    "STDEV": 0.87,
    "#": 8,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 6,
    "whisky": "Auchentoshan Bartender's Malt",
    "rating": 8.45,
    "STDEV": 0.48,
    "#": 8,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 6,
    "whisky": "Auchentoshan Classic",
    "rating": 7.96,
    "STDEV": 0.4,
    "#": 9,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 6,
    "whisky": "Auchentoshan Three Wood",
    "rating": 8.26,
    "STDEV": 0.42,
    "#": 23,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 6,
    "whisky": "Auchentoshan Valinch",
    "rating": 8.3,
    "STDEV": 0.61,
    "#": 14,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 6,
    "whisky": "Auchentoshan Virgin Oak",
    "rating": 8.2,
    "STDEV": 0.55,
    "#": 7,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 7,
    "whisky": "Auchroisk 10yo F&F",
    "rating": 8.14,
    "STDEV": 0.09,
    "#": 4,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 8,
    "whisky": "Aultmore 12yo",
    "rating": 8.49,
    "STDEV": 0.22,
    "#": 15,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 8,
    "whisky": "Aultmore 18yo",
    "rating": 8.58,
    "STDEV": 0.18,
    "#": 6,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 8,
    "whisky": "Aultmore 25yo",
    "rating": 8.97,
    "STDEV": 0.21,
    "#": 5,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 9,
    "whisky": "Balblair 1989",
    "rating": 8.57,
    "STDEV": 0.4,
    "#": 11,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 9,
    "whisky": "Balblair 1990 Second Release",
    "rating": 8.8,
    "STDEV": 0.23,
    "#": 12,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 9,
    "whisky": "Balblair 2000",
    "rating": 8.47,
    "STDEV": 0.36,
    "#": 13,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach 10yo",
    "rating": 8.54,
    "STDEV": 0.16,
    "#": 12,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach 10yo Curiositas",
    "rating": 8.61,
    "STDEV": 0.28,
    "#": 20,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach 12yo",
    "rating": 8.42,
    "STDEV": 0.25,
    "#": 15,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach 12yo Horizons",
    "rating": 8.35,
    "STDEV": 0.32,
    "#": 7,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach 12yo Matured in Sherry Wood",
    "rating": 8.66,
    "STDEV": 0.2,
    "#": 14,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach 15yo Sauternes Finish",
    "rating": 8.09,
    "STDEV": 0.51,
    "#": 3,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "F",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach 15yo Tawny Port Finish",
    "rating": 8.43,
    "STDEV": 0.28,
    "#": 13,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach 17yo Septendecim Peated",
    "rating": 8.54,
    "STDEV": 0.53,
    "#": 18,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach 17yo Solstice 1st Peated Port Finish",
    "rating": 8.91,
    "STDEV": 0.21,
    "#": 4,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach 17yo Solstice 2nd Peated Port Finish",
    "rating": 8.93,
    "STDEV": 0.42,
    "#": 14,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach 17yo Solstice Peated Port both editions",
    "rating": 8.96,
    "STDEV": 0.28,
    "#": 14,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach 18yo Albariza Pedro Ximenez Peated",
    "rating": 9.04,
    "STDEV": 0.2,
    "#": 7,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach 18yo Dunder",
    "rating": 8.79,
    "STDEV": 0.29,
    "#": 5,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach 18yo Latada",
    "rating": 8.84,
    "STDEV": 0.16,
    "#": 7,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach 20yo",
    "rating": 8.3,
    "STDEV": 0.7,
    "#": 11,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach 21yo Authenticus Peated",
    "rating": 8.89,
    "STDEV": 0.33,
    "#": 15,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach 21yo Tawny Port Finish",
    "rating": 8.64,
    "STDEV": 0.32,
    "#": 3,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach 25yo",
    "rating": 8.78,
    "STDEV": 0.43,
    "#": 15,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach 25yo Authenticus Peated",
    "rating": 8.78,
    "STDEV": 0.47,
    "#": 11,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach 35yo",
    "rating": 8.92,
    "STDEV": 0.6,
    "#": 5,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach Birnie Moss",
    "rating": 8.26,
    "STDEV": 0.33,
    "#": 11,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach Cask Strength",
    "rating": 8.6,
    "STDEV": 0.43,
    "#": 9,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach Heart of Speyside",
    "rating": 7.72,
    "STDEV": 0.88,
    "#": 7,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 10,
    "whisky": "BenRiach Peated Quarter Casks",
    "rating": 8.56,
    "STDEV": 0.32,
    "#": 4,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 11,
    "whisky": "Benrinnes 15yo F&F",
    "rating": 8.31,
    "STDEV": 0.81,
    "#": 7,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 12,
    "whisky": "Benromach 10yo",
    "rating": 8.67,
    "STDEV": 0.26,
    "#": 23,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 12,
    "whisky": "Benromach 10yo Cask Strength 100 proof",
    "rating": 9.03,
    "STDEV": 0.18,
    "#": 14,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 12,
    "whisky": "Benromach 15yo",
    "rating": 8.7,
    "STDEV": 0.45,
    "#": 12,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 12,
    "whisky": "Benromach 5yo",
    "rating": 8.8,
    "STDEV": 0.19,
    "#": 4,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 12,
    "whisky": "Benromach Organic",
    "rating": 8.43,
    "STDEV": 0.54,
    "#": 16,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 12,
    "whisky": "Benromach Peat Smoke",
    "rating": 8.43,
    "STDEV": 0.27,
    "#": 15,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 12,
    "whisky": "Benromach Sassicaia",
    "rating": 8.38,
    "STDEV": 0.15,
    "#": 7,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 12,
    "whisky": "Benromach Traditional",
    "rating": 8.44,
    "STDEV": 0.43,
    "#": 13,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 13,
    "whisky": "Bladnoch 12yo Sherry Cask Sheep label",
    "rating": 8.99,
    "STDEV": 0.26,
    "#": 3,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 13,
    "whisky": "Bladnoch 15yo Adela",
    "rating": 8.28,
    "STDEV": 0.31,
    "#": 6,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 13,
    "whisky": "Bladnoch 16yo Signatory",
    "rating": 7.94,
    "STDEV": 0.44,
    "#": 4,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 13,
    "whisky": "Bladnoch 1993-2009 G&M",
    "rating": 8.23,
    "STDEV": 0.2,
    "#": 5,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 13,
    "whisky": "Bladnoch 19yo Cow label",
    "rating": 8.73,
    "STDEV": 0.22,
    "#": 4,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 13,
    "whisky": "Bladnoch 9yo Lightly Peated",
    "rating": 8.87,
    "STDEV": 0.58,
    "#": 4,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 13,
    "whisky": "Bladnoch Samsara",
    "rating": 8,
    "STDEV": 0.69,
    "#": 4,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 13,
    "whisky": "Bladnoch Vintage 1992-2007 Signatory",
    "rating": 8.27,
    "STDEV": 0.44,
    "#": 4,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 14,
    "whisky": "Blair Athol 12yo F&F",
    "rating": 8.44,
    "STDEV": 0.43,
    "#": 9,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 15,
    "whisky": "Bowmore 10yo Devil's Cask all batches",
    "rating": 8.76,
    "STDEV": 0.32,
    "#": 19,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 15,
    "whisky": "Bowmore 10yo Tempest",
    "rating": 8.77,
    "STDEV": 0.23,
    "#": 21,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 15,
    "whisky": "Bowmore 12yo",
    "rating": 8.38,
    "STDEV": 0.28,
    "#": 20,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 15,
    "whisky": "Bowmore 12yo Enigma",
    "rating": 8.55,
    "STDEV": 0.36,
    "#": 12,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 15,
    "whisky": "Bowmore 15yo Darkest",
    "rating": 8.61,
    "STDEV": 0.31,
    "#": 23,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 15,
    "whisky": "Bowmore 15yo Laimrig",
    "rating": 8.96,
    "STDEV": 0.18,
    "#": 17,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 15,
    "whisky": "Bowmore 15yo Mariner",
    "rating": 8.62,
    "STDEV": 0.47,
    "#": 7,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 15,
    "whisky": "Bowmore 17yo",
    "rating": 8.4,
    "STDEV": 0.64,
    "#": 8,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 15,
    "whisky": "Bowmore 17yo White Sands",
    "rating": 8.59,
    "STDEV": 0.31,
    "#": 7,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 15,
    "whisky": "Bowmore 18yo",
    "rating": 8.54,
    "STDEV": 0.41,
    "#": 22,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 15,
    "whisky": "Bowmore 25yo",
    "rating": 8.79,
    "STDEV": 0.5,
    "#": 10,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 15,
    "whisky": "Bowmore Black Rock",
    "rating": 8.23,
    "STDEV": 0.33,
    "#": 9,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 15,
    "whisky": "Bowmore Gold Reef",
    "rating": 8.34,
    "STDEV": 0.32,
    "#": 11,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 15,
    "whisky": "Bowmore Legend",
    "rating": 7.67,
    "STDEV": 0.56,
    "#": 10,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 15,
    "whisky": "Bowmore Mizunara Cask Finish",
    "rating": 8.85,
    "STDEV": 0.43,
    "#": 3,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 15,
    "whisky": "Bowmore No.1",
    "rating": 8.37,
    "STDEV": 0.3,
    "#": 6,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 15,
    "whisky": "Bowmore Small Batch",
    "rating": 8.22,
    "STDEV": 0.51,
    "#": 13,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 15,
    "whisky": "Bowmore Springtide",
    "rating": 9.04,
    "STDEV": 0.67,
    "#": 5,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 15,
    "whisky": "Bowmore Vault Edition First Release",
    "rating": 8.61,
    "STDEV": 0.28,
    "#": 13,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich 21yo CuvÃ©e 382 La Berenice",
    "rating": 8.7,
    "STDEV": 0.57,
    "#": 8,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich 21yo CuvÃ©e 407 PX",
    "rating": 9.06,
    "STDEV": 0.21,
    "#": 5,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich 21yo CuvÃ©e 640 Eroica",
    "rating": 8.79,
    "STDEV": 0.42,
    "#": 10,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich 8yo Laddie Eight",
    "rating": 8.21,
    "STDEV": 0.64,
    "#": 4,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Black Art 1989",
    "rating": 8.25,
    "STDEV": 0.38,
    "#": 4,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Black Art 2.x 1989",
    "rating": 9.01,
    "STDEV": 0.16,
    "#": 8,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Black Art 3.x 1989",
    "rating": 9.09,
    "STDEV": 0.4,
    "#": 12,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Black Art 4.x 1990",
    "rating": 8.62,
    "STDEV": 0.6,
    "#": 12,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Black Art 5.x 1992",
    "rating": 8.91,
    "STDEV": 0.35,
    "#": 7,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Black Art 6.x 1990",
    "rating": 9.21,
    "STDEV": 0.1,
    "#": 3,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Classic Laddie Scottish Barley",
    "rating": 8.48,
    "STDEV": 0.34,
    "#": 14,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Infinity Third Edition",
    "rating": 8.76,
    "STDEV": 0.27,
    "#": 5,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Islay Barley all vintages",
    "rating": 8.59,
    "STDEV": 0.15,
    "#": 14,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Laddie Classic Edition 01",
    "rating": 8.41,
    "STDEV": 0.52,
    "#": 7,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Laddie Eight 8yo",
    "rating": 8.42,
    "STDEV": 0.46,
    "#": 7,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Laddie Sixteen 16yo",
    "rating": 8.75,
    "STDEV": 0.21,
    "#": 13,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Laddie Ten 10yo",
    "rating": 8.81,
    "STDEV": 0.28,
    "#": 18,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Laddie Ten 10yo Second Edition",
    "rating": 8.86,
    "STDEV": 0.24,
    "#": 10,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Laddie Twenty Two 22yo",
    "rating": 8.88,
    "STDEV": 0.26,
    "#": 11,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Octomore 10",
    "rating": 8.88,
    "STDEV": 0.38,
    "#": 5,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Octomore 10 Second Edition",
    "rating": 9,
    "STDEV": 0.22,
    "#": 11,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Octomore 6.1",
    "rating": 8.76,
    "STDEV": 0.29,
    "#": 16,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Octomore 6.2",
    "rating": 9.07,
    "STDEV": 0.19,
    "#": 10,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Octomore 6.3",
    "rating": 8.79,
    "STDEV": 0.64,
    "#": 10,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Octomore 7.1",
    "rating": 8.98,
    "STDEV": 0.27,
    "#": 14,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Octomore 7.2",
    "rating": 8.84,
    "STDEV": 0.47,
    "#": 10,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Octomore 7.3",
    "rating": 9.03,
    "STDEV": 0.52,
    "#": 10,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Octomore 7.4",
    "rating": 8.59,
    "STDEV": 0.65,
    "#": 9,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Octomore 8.1",
    "rating": 8.87,
    "STDEV": 0.14,
    "#": 12,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Octomore 8.2",
    "rating": 9.05,
    "STDEV": 0.19,
    "#": 5,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Octomore 8.3",
    "rating": 9.06,
    "STDEV": 0.15,
    "#": 10,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Octomore 9.1",
    "rating": 8.69,
    "STDEV": 0.39,
    "#": 5,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Octomore 9.3",
    "rating": 9.17,
    "STDEV": 0.1,
    "#": 6,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Port Charlotte 10yo Heavily Peated First Edition",
    "rating": 8.77,
    "STDEV": 0.2,
    "#": 8,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Port Charlotte 10yo Heavily Peated Second Edition",
    "rating": 8.88,
    "STDEV": 0.37,
    "#": 9,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Port Charlotte An Turas Mor",
    "rating": 8.72,
    "STDEV": 0.25,
    "#": 12,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Port Charlotte Islay Barley Heavily Peated",
    "rating": 8.77,
    "STDEV": 0.21,
    "#": 6,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Port Charlotte PC10 Second Edition",
    "rating": 8.81,
    "STDEV": 0.2,
    "#": 6,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Port Charlotte PC10 Tro Na Linntean",
    "rating": 8.95,
    "STDEV": 0.41,
    "#": 10,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Port Charlotte PC11 Eorna Na H-Alba",
    "rating": 8.91,
    "STDEV": 0.27,
    "#": 6,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Port Charlotte PC12 Oileanach Furachail",
    "rating": 8.83,
    "STDEV": 0.45,
    "#": 11,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Port Charlotte Scottish Barley Heavily Peated",
    "rating": 8.74,
    "STDEV": 0.24,
    "#": 17,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Port Charlotte The Peat Project",
    "rating": 8.49,
    "STDEV": 0.39,
    "#": 7,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Rocks",
    "rating": 8.35,
    "STDEV": 0.3,
    "#": 11,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 16,
    "whisky": "Bruichladdich Sherry Classic",
    "rating": 8.21,
    "STDEV": 0.89,
    "#": 6,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 17,
    "whisky": "Bunnahabhain 12yo",
    "rating": 8.65,
    "STDEV": 0.26,
    "#": 25,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "F",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 17,
    "whisky": "Bunnahabhain 14yo 2003 Pedro Ximenez Finish",
    "rating": 8.81,
    "STDEV": 0.61,
    "#": 10,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 17,
    "whisky": "Bunnahabhain 18yo",
    "rating": 8.98,
    "STDEV": 0.2,
    "#": 18,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 17,
    "whisky": "Bunnahabhain 25yo",
    "rating": 8.87,
    "STDEV": 0.33,
    "#": 17,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 17,
    "whisky": "Bunnahabhain 40yo",
    "rating": 9.12,
    "STDEV": 0.34,
    "#": 6,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "F",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 17,
    "whisky": "Bunnahabhain CeÃ²banach",
    "rating": 8.79,
    "STDEV": 0.28,
    "#": 14,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 17,
    "whisky": "Bunnahabhain Cruach Mhona",
    "rating": 8.34,
    "STDEV": 0.38,
    "#": 8,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 17,
    "whisky": "Bunnahabhain Darach Ur",
    "rating": 8.39,
    "STDEV": 0.31,
    "#": 10,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "F",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 17,
    "whisky": "Bunnahabhain Eirigh Na Greine",
    "rating": 8.41,
    "STDEV": 0.44,
    "#": 10,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "F",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 17,
    "whisky": "Bunnahabhain Moine all bottlings",
    "rating": 8.6,
    "STDEV": 0.55,
    "#": 12,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 17,
    "whisky": "Bunnahabhain Stiuireadair",
    "rating": 8.42,
    "STDEV": 0.34,
    "#": 6,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "F",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 17,
    "whisky": "Bunnahabhain Toiteach",
    "rating": 8.56,
    "STDEV": 0.36,
    "#": 16,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 18,
    "whisky": "Caol Ila 12yo",
    "rating": 8.74,
    "STDEV": 0.18,
    "#": 22,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 18,
    "whisky": "Caol Ila 15yo Unpeated",
    "rating": 8.71,
    "STDEV": 0.41,
    "#": 12,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 18,
    "whisky": "Caol Ila 17yo Unpeated 2015",
    "rating": 8.6,
    "STDEV": 0.46,
    "#": 11,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 18,
    "whisky": "Caol Ila 18yo",
    "rating": 8.75,
    "STDEV": 0.5,
    "#": 18,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 18,
    "whisky": "Caol Ila 18yo Unpeated 2017",
    "rating": 8.9,
    "STDEV": 0.31,
    "#": 12,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 18,
    "whisky": "Caol Ila 25yo",
    "rating": 8.87,
    "STDEV": 0.2,
    "#": 12,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 18,
    "whisky": "Caol Ila 30yo",
    "rating": 9.3,
    "STDEV": 0.21,
    "#": 12,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 18,
    "whisky": "Caol Ila Distiller's Edition all editions",
    "rating": 8.68,
    "STDEV": 0.34,
    "#": 16,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 19,
    "whisky": "Cardhu 12yo",
    "rating": 8.06,
    "STDEV": 0.48,
    "#": 21,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 19,
    "whisky": "Cardhu Amber Rock",
    "rating": 8.25,
    "STDEV": 0.29,
    "#": 4,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 20,
    "whisky": "Clynelish 14yo",
    "rating": 8.76,
    "STDEV": 0.26,
    "#": 18,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "F",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 21,
    "whisky": "Dalmore 12yo",
    "rating": 8.42,
    "STDEV": 0.28,
    "#": 20,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 21,
    "whisky": "Dalmore 15yo",
    "rating": 8.3,
    "STDEV": 0.46,
    "#": 21,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 21,
    "whisky": "Dalmore 18yo",
    "rating": 8.54,
    "STDEV": 0.39,
    "#": 14,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 21,
    "whisky": "Dalmore Cigar Malt",
    "rating": 8.41,
    "STDEV": 0.4,
    "#": 7,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 21,
    "whisky": "Dalmore Cigar Malt Reserve",
    "rating": 8.29,
    "STDEV": 0.64,
    "#": 13,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 21,
    "whisky": "Dalmore Gran Reserva",
    "rating": 8.08,
    "STDEV": 0.33,
    "#": 10,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 21,
    "whisky": "Dalmore King Alexander III",
    "rating": 8.24,
    "STDEV": 0.47,
    "#": 12,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 21,
    "whisky": "Dalmore Valour",
    "rating": 8.04,
    "STDEV": 0.34,
    "#": 11,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 22,
    "whisky": "Dalwhinnie 15yo",
    "rating": 8.64,
    "STDEV": 0.34,
    "#": 20,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 22,
    "whisky": "Dalwhinnie Distillers Edition",
    "rating": 8.54,
    "STDEV": 0.28,
    "#": 10,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 23,
    "whisky": "Deanston 10yo PX Finish",
    "rating": 8.71,
    "STDEV": 0.45,
    "#": 5,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 23,
    "whisky": "Deanston 12yo",
    "rating": 8.22,
    "STDEV": 0.46,
    "#": 19,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "F",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 23,
    "whisky": "Deanston 18yo Bourbon Finish",
    "rating": 8.56,
    "STDEV": 0.47,
    "#": 7,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 23,
    "whisky": "Deanston 2008 Bordeaux Red Wine Cask Matured",
    "rating": 8.83,
    "STDEV": 0.22,
    "#": 5,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 23,
    "whisky": "Deanston Virgin Oak",
    "rating": 8.15,
    "STDEV": 0.43,
    "#": 15,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 24,
    "whisky": "Singleton of Dufftown 12yo",
    "rating": 7.91,
    "STDEV": 0.47,
    "#": 11,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 24,
    "whisky": "Singleton of Dufftown 15yo",
    "rating": 8.32,
    "STDEV": 0.19,
    "#": 7,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 24,
    "whisky": "Singleton of Dufftown 18yo",
    "rating": 8.34,
    "STDEV": 0.13,
    "#": 6,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 24,
    "whisky": "Singleton of Dufftown Spey Cascade",
    "rating": 7.48,
    "STDEV": 0.58,
    "#": 3,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 24,
    "whisky": "Singleton of Dufftown Tailfire",
    "rating": 8.15,
    "STDEV": 0.41,
    "#": 4,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 24,
    "whisky": "Singleton of Dufftown UnitÃ©",
    "rating": 8.08,
    "STDEV": 0.29,
    "#": 4,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 25,
    "whisky": "Edradour 10yo",
    "rating": 8.22,
    "STDEV": 0.69,
    "#": 17,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 26,
    "whisky": "Glen Deveron 10yo Macduff",
    "rating": 7.96,
    "STDEV": 0.37,
    "#": 5,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 27,
    "whisky": "Glen Elgin 12yo",
    "rating": 8.42,
    "STDEV": 0.21,
    "#": 16,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 28,
    "whisky": "Glen Garioch 12yo",
    "rating": 8.65,
    "STDEV": 0.3,
    "#": 17,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "F",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 28,
    "whisky": "Glen Garioch 15yo Renaissance Chapter 1",
    "rating": 8.79,
    "STDEV": 0.24,
    "#": 7,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 28,
    "whisky": "Glen Garioch 15yo Wine Cask Matured",
    "rating": 8.69,
    "STDEV": 0.24,
    "#": 4,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 28,
    "whisky": "Glen Garioch 16yo Renaissance Chapter 2",
    "rating": 8.23,
    "STDEV": 0.57,
    "#": 5,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 28,
    "whisky": "Glen Garioch 1991",
    "rating": 8.94,
    "STDEV": 0.18,
    "#": 3,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 28,
    "whisky": "Glen Garioch 1994",
    "rating": 8.96,
    "STDEV": 0.37,
    "#": 8,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 28,
    "whisky": "Glen Garioch 1995",
    "rating": 8.95,
    "STDEV": 0.31,
    "#": 11,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 28,
    "whisky": "Glen Garioch 1997",
    "rating": 8.51,
    "STDEV": 0.17,
    "#": 6,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 28,
    "whisky": "Glen Garioch 1998 Wine Cask Matured",
    "rating": 8.86,
    "STDEV": 0.25,
    "#": 3,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 28,
    "whisky": "Glen Garioch 1999 Sherry Cask Matured",
    "rating": 8.47,
    "STDEV": 0.95,
    "#": 8,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 28,
    "whisky": "Glen Garioch Founder's Reserve",
    "rating": 8.38,
    "STDEV": 0.35,
    "#": 19,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 28,
    "whisky": "Glen Garioch Virgin Oak",
    "rating": 8.08,
    "STDEV": 0.5,
    "#": 6,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 29,
    "whisky": "Glen Grant",
    "rating": 8.26,
    "STDEV": 0.47,
    "#": 9,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 29,
    "whisky": "Glen Grant 10yo G&M",
    "rating": 8.45,
    "STDEV": 0.3,
    "#": 4,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 29,
    "whisky": "Glen Grant 12yo",
    "rating": 8.27,
    "STDEV": 0.52,
    "#": 9,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 29,
    "whisky": "Glen Grant 16yo",
    "rating": 8.5,
    "STDEV": 0.3,
    "#": 7,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 29,
    "whisky": "Glen Grant 18yo",
    "rating": 8.47,
    "STDEV": 0.68,
    "#": 8,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 29,
    "whisky": "Glen Grant 25yo",
    "rating": 8.88,
    "STDEV": 0.18,
    "#": 3,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 29,
    "whisky": "Glen Grant Five Decades",
    "rating": 8.77,
    "STDEV": 0.23,
    "#": 6,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 29,
    "whisky": "Glen Grant The Major's Reserve",
    "rating": 7.89,
    "STDEV": 0.59,
    "#": 11,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 30,
    "whisky": "Glen Moray 10yo Chardonnay Cask",
    "rating": 8.1,
    "STDEV": 0.54,
    "#": 14,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "F",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 30,
    "whisky": "Glen Moray 12yo",
    "rating": 8.04,
    "STDEV": 0.28,
    "#": 14,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 30,
    "whisky": "Glen Moray 12yo Chenin Blanc",
    "rating": 7.86,
    "STDEV": 0.76,
    "#": 6,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "F",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 30,
    "whisky": "Glen Moray 16yo",
    "rating": 8.24,
    "STDEV": 0.54,
    "#": 8,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 30,
    "whisky": "Glen Moray 16yo Chenin Blanc",
    "rating": 7.58,
    "STDEV": 0.57,
    "#": 4,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "F",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 30,
    "whisky": "Glen Moray Classic",
    "rating": 8.02,
    "STDEV": 0.19,
    "#": 5,
    "cost": "$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 30,
    "whisky": "Glen Moray Classic Peated",
    "rating": 8.42,
    "STDEV": 0.29,
    "#": 5,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 30,
    "whisky": "Glen Moray Classic Port Cask finish",
    "rating": 8.36,
    "STDEV": 0.21,
    "#": 4,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 30,
    "whisky": "Glen Moray Classic Sherry Cask finish",
    "rating": 8.48,
    "STDEV": 0.21,
    "#": 5,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 31,
    "whisky": "Singleton of Glen Ord 12yo",
    "rating": 8.28,
    "STDEV": 0.28,
    "#": 6,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 31,
    "whisky": "Singleton of Glen Ord 15yo",
    "rating": 8.47,
    "STDEV": 0.4,
    "#": 5,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 31,
    "whisky": "Singleton of Glen Ord 18yo",
    "rating": 8.35,
    "STDEV": 0.29,
    "#": 3,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 31,
    "whisky": "Singleton of Glen Ord Signature",
    "rating": 7.9,
    "STDEV": 0.3,
    "#": 3,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 32,
    "whisky": "Glen Scotia 12yo",
    "rating": 8.28,
    "STDEV": 0.55,
    "#": 9,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 33,
    "whisky": "Glen Spey 12yo F&F",
    "rating": 8.06,
    "STDEV": 0.44,
    "#": 5,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 34,
    "whisky": "GlenDronach 12yo Original",
    "rating": 8.58,
    "STDEV": 0.23,
    "#": 22,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 34,
    "whisky": "GlenDronach 12yo Sauternes Cask Finish",
    "rating": 8.83,
    "STDEV": 0.18,
    "#": 6,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 34,
    "whisky": "GlenDronach 14yo Sauternes Cask Finish",
    "rating": 8.36,
    "STDEV": 0.4,
    "#": 6,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 34,
    "whisky": "GlenDronach 14yo Virgin Oak",
    "rating": 8.52,
    "STDEV": 0.37,
    "#": 11,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 34,
    "whisky": "GlenDronach 15yo Revival",
    "rating": 8.87,
    "STDEV": 0.28,
    "#": 22,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 34,
    "whisky": "GlenDronach 15yo Tawny Port Finish",
    "rating": 8.59,
    "STDEV": 0.48,
    "#": 7,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 34,
    "whisky": "GlenDronach 18yo Allardice",
    "rating": 8.73,
    "STDEV": 0.37,
    "#": 20,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 34,
    "whisky": "GlenDronach 18yo Marsala Finish",
    "rating": 8.67,
    "STDEV": 0.09,
    "#": 4,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 34,
    "whisky": "GlenDronach 18yo Tawny Port Finish",
    "rating": 8.76,
    "STDEV": 0.36,
    "#": 7,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 34,
    "whisky": "GlenDronach 19yo Madeira Finish",
    "rating": 9.04,
    "STDEV": 0.24,
    "#": 5,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 34,
    "whisky": "GlenDronach 21yo Parliament",
    "rating": 8.73,
    "STDEV": 0.34,
    "#": 21,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 34,
    "whisky": "GlenDronach 8yo The Hielan",
    "rating": 8.44,
    "STDEV": 0.31,
    "#": 11,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 34,
    "whisky": "GlenDronach Cask Strength all batches",
    "rating": 8.9,
    "STDEV": 0.14,
    "#": 19,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 34,
    "whisky": "GlenDronach Cask Strength batch 1",
    "rating": 9.04,
    "STDEV": 0.27,
    "#": 6,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 34,
    "whisky": "GlenDronach Cask Strength batch 2",
    "rating": 9.05,
    "STDEV": 0.13,
    "#": 8,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 34,
    "whisky": "GlenDronach Cask Strength batch 3",
    "rating": 8.94,
    "STDEV": 0.37,
    "#": 7,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 34,
    "whisky": "GlenDronach Cask Strength batch 4",
    "rating": 8.91,
    "STDEV": 0.25,
    "#": 13,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 34,
    "whisky": "GlenDronach Cask Strength batch 5",
    "rating": 8.79,
    "STDEV": 0.16,
    "#": 9,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 34,
    "whisky": "GlenDronach Cask Strength batch 6",
    "rating": 8.84,
    "STDEV": 0.17,
    "#": 5,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 34,
    "whisky": "GlenDronach Cask Strength batch 7",
    "rating": 8.87,
    "STDEV": 0.09,
    "#": 3,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 34,
    "whisky": "GlenDronach Peated",
    "rating": 8.54,
    "STDEV": 0.3,
    "#": 11,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 34,
    "whisky": "GlenDronach vintage 19yo Single Cask all vintages",
    "rating": 8.95,
    "STDEV": 0.33,
    "#": 16,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 34,
    "whisky": "GlenDronach vintage 20yo Single Cask all vintages",
    "rating": 9,
    "STDEV": 0.35,
    "#": 14,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 35,
    "whisky": "Singleton of Glendullan 12yo",
    "rating": 8.04,
    "STDEV": 0.42,
    "#": 14,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 35,
    "whisky": "Singleton of Glendullan 15yo",
    "rating": 8.51,
    "STDEV": 0.38,
    "#": 4,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 36,
    "whisky": "Glenfarclas 105",
    "rating": 8.71,
    "STDEV": 0.35,
    "#": 25,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 36,
    "whisky": "Glenfarclas 10yo",
    "rating": 8.32,
    "STDEV": 0.32,
    "#": 17,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 36,
    "whisky": "Glenfarclas 12yo",
    "rating": 8.57,
    "STDEV": 0.32,
    "#": 21,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 36,
    "whisky": "Glenfarclas 15yo",
    "rating": 8.61,
    "STDEV": 0.48,
    "#": 18,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 36,
    "whisky": "Glenfarclas 17yo",
    "rating": 8.74,
    "STDEV": 0.27,
    "#": 21,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 36,
    "whisky": "Glenfarclas 1968-2000 54.2%",
    "rating": 8.54,
    "STDEV": 0.23,
    "#": 3,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 36,
    "whisky": "Glenfarclas 21yo",
    "rating": 8.62,
    "STDEV": 0.29,
    "#": 16,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 36,
    "whisky": "Glenfarclas 25yo",
    "rating": 8.75,
    "STDEV": 0.28,
    "#": 15,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 36,
    "whisky": "Glenfarclas 40yo",
    "rating": 9.27,
    "STDEV": 0.26,
    "#": 15,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 37,
    "whisky": "Glenfiddich 12yo",
    "rating": 8.1,
    "STDEV": 0.22,
    "#": 26,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 37,
    "whisky": "Glenfiddich 12yo Caoran Reserve",
    "rating": 7.83,
    "STDEV": 0.51,
    "#": 8,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 37,
    "whisky": "Glenfiddich 14yo Bourbon Barrel Reserve",
    "rating": 8.28,
    "STDEV": 0.3,
    "#": 11,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 37,
    "whisky": "Glenfiddich 14yo Rich Oak",
    "rating": 8.56,
    "STDEV": 0.33,
    "#": 11,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 37,
    "whisky": "Glenfiddich 15yo Distillery Edition",
    "rating": 8.69,
    "STDEV": 0.29,
    "#": 15,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 37,
    "whisky": "Glenfiddich 15yo Solera",
    "rating": 8.59,
    "STDEV": 0.25,
    "#": 26,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 37,
    "whisky": "Glenfiddich 18yo",
    "rating": 8.59,
    "STDEV": 0.34,
    "#": 22,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 37,
    "whisky": "Glenfiddich 1963 Original Malt",
    "rating": 8.26,
    "STDEV": 0.42,
    "#": 8,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 37,
    "whisky": "Glenfiddich 19yo Age of Discovery Bourbon Cask",
    "rating": 8.71,
    "STDEV": 0.26,
    "#": 10,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 37,
    "whisky": "Glenfiddich 19yo Age of Discovery Madeira Cask",
    "rating": 8.51,
    "STDEV": 0.43,
    "#": 14,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 37,
    "whisky": "Glenfiddich 21yo Gran Reserva",
    "rating": 8.62,
    "STDEV": 0.39,
    "#": 16,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 37,
    "whisky": "Glenfiddich 26yo Excellence",
    "rating": 8.69,
    "STDEV": 0.46,
    "#": 6,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 37,
    "whisky": "Glenfiddich 30yo",
    "rating": 9.05,
    "STDEV": 0.1,
    "#": 4,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 37,
    "whisky": "Glenfiddich Cask of Dreams",
    "rating": 8.74,
    "STDEV": 0.21,
    "#": 8,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "F",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 37,
    "whisky": "Glenfiddich Fire & Cane Experimental Series No. 4",
    "rating": 8.61,
    "STDEV": 0.21,
    "#": 9,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 37,
    "whisky": "Glenfiddich IPA Cask Finish Experimental Series No. 1",
    "rating": 8.44,
    "STDEV": 0.27,
    "#": 15,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 37,
    "whisky": "Glenfiddich Malt Master's Edition",
    "rating": 8.29,
    "STDEV": 0.3,
    "#": 7,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 37,
    "whisky": "Glenfiddich Project XX Experimental Series No. 2",
    "rating": 8.79,
    "STDEV": 0.22,
    "#": 10,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 37,
    "whisky": "Glenfiddich Reserve Cask",
    "rating": 8.23,
    "STDEV": 0.25,
    "#": 8,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 37,
    "whisky": "Glenfiddich Select Cask",
    "rating": 8.18,
    "STDEV": 0.39,
    "#": 10,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 37,
    "whisky": "Glenfiddich Snow Phoenix",
    "rating": 8.76,
    "STDEV": 0.37,
    "#": 11,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 37,
    "whisky": "Glenfiddich Vintage Cask",
    "rating": 8.48,
    "STDEV": 0.26,
    "#": 6,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 37,
    "whisky": "Glenfiddich Winter Storm 21yo Experimental Series No. 3",
    "rating": 8.77,
    "STDEV": 0.37,
    "#": 9,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "F",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 38,
    "whisky": "Glengoyne 10yo",
    "rating": 8.26,
    "STDEV": 0.3,
    "#": 14,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 38,
    "whisky": "Glengoyne 12yo",
    "rating": 8.53,
    "STDEV": 0.34,
    "#": 12,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 38,
    "whisky": "Glengoyne 12yo Cask Strength",
    "rating": 8.55,
    "STDEV": 0.42,
    "#": 10,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 38,
    "whisky": "Glengoyne 15yo",
    "rating": 8.5,
    "STDEV": 0.52,
    "#": 10,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 38,
    "whisky": "Glengoyne 17yo",
    "rating": 8.55,
    "STDEV": 0.27,
    "#": 14,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 38,
    "whisky": "Glengoyne 18yo",
    "rating": 8.57,
    "STDEV": 0.32,
    "#": 16,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 38,
    "whisky": "Glengoyne 21yo",
    "rating": 8.88,
    "STDEV": 0.19,
    "#": 17,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 38,
    "whisky": "Glengoyne 25yo",
    "rating": 9.2,
    "STDEV": 0.23,
    "#": 14,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 38,
    "whisky": "Glengoyne 40yo",
    "rating": 8.91,
    "STDEV": 0.82,
    "#": 3,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 38,
    "whisky": "Glengoyne Cask Strength all batches",
    "rating": 8.64,
    "STDEV": 0.47,
    "#": 13,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 38,
    "whisky": "Glengoyne Cask Strength batch 1",
    "rating": 8.73,
    "STDEV": 0.47,
    "#": 4,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 38,
    "whisky": "Glengoyne Cask Strength batch 2",
    "rating": 8.66,
    "STDEV": 0.41,
    "#": 3,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 38,
    "whisky": "Glengoyne Cask Strength batch 3",
    "rating": 8.47,
    "STDEV": 0.72,
    "#": 6,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 38,
    "whisky": "Glengoyne Cask Strength batch 4",
    "rating": 8.67,
    "STDEV": 0.08,
    "#": 5,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 38,
    "whisky": "Glengoyne Teapot Dram all batches",
    "rating": 8.8,
    "STDEV": 0.44,
    "#": 6,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 39,
    "whisky": "Glenkinchie 12yo",
    "rating": 8.23,
    "STDEV": 0.24,
    "#": 17,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 39,
    "whisky": "Glenkinchie Distiller's Edition all editions",
    "rating": 8.39,
    "STDEV": 0.25,
    "#": 13,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 40,
    "whisky": "Glenlivet 12yo",
    "rating": 8.08,
    "STDEV": 0.29,
    "#": 22,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 40,
    "whisky": "Glenlivet 15yo French Oak",
    "rating": 8.38,
    "STDEV": 0.25,
    "#": 19,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "F",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 40,
    "whisky": "Glenlivet 18yo",
    "rating": 8.58,
    "STDEV": 0.22,
    "#": 24,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 40,
    "whisky": "Glenlivet 21yo Archive all batches",
    "rating": 8.82,
    "STDEV": 0.28,
    "#": 13,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 40,
    "whisky": "Glenlivet Alpha",
    "rating": 8.95,
    "STDEV": 0.1,
    "#": 4,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 40,
    "whisky": "Glenlivet Cipher",
    "rating": 8.61,
    "STDEV": 0.33,
    "#": 7,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 40,
    "whisky": "Glenlivet Founder's Reserve",
    "rating": 7.97,
    "STDEV": 0.35,
    "#": 18,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 40,
    "whisky": "Glenlivet Nadurra 16yo",
    "rating": 8.85,
    "STDEV": 0.2,
    "#": 23,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 40,
    "whisky": "Glenlivet Nadurra Cask Strength NAS",
    "rating": 8.81,
    "STDEV": 0.09,
    "#": 3,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 40,
    "whisky": "Glenlivet Nadurra First Fill White Oak",
    "rating": 8.9,
    "STDEV": 0.23,
    "#": 5,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 40,
    "whisky": "Glenlivet Nadurra Oloroso",
    "rating": 8.37,
    "STDEV": 0.66,
    "#": 13,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 40,
    "whisky": "Glenlivet Nadurra Peated Cask Finish",
    "rating": 8.76,
    "STDEV": 0.25,
    "#": 4,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 40,
    "whisky": "Glenlivet XXV 25yo",
    "rating": 8.9,
    "STDEV": 0.18,
    "#": 11,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 41,
    "whisky": "Glenlossie 10yo F&F",
    "rating": 8.18,
    "STDEV": 0.31,
    "#": 9,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 42,
    "whisky": "Glenmorangie 10yo",
    "rating": 8.44,
    "STDEV": 0.43,
    "#": 25,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 42,
    "whisky": "Glenmorangie 18yo",
    "rating": 8.7,
    "STDEV": 0.28,
    "#": 18,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 42,
    "whisky": "Glenmorangie A Midwinter Nightâ€™s Dram",
    "rating": 8.55,
    "STDEV": 0.3,
    "#": 8,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 42,
    "whisky": "Glenmorangie Astar",
    "rating": 8.86,
    "STDEV": 0.25,
    "#": 17,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 42,
    "whisky": "Glenmorangie Bacalta",
    "rating": 8.78,
    "STDEV": 0.32,
    "#": 8,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 42,
    "whisky": "Glenmorangie Companta",
    "rating": 8.82,
    "STDEV": 0.54,
    "#": 14,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 42,
    "whisky": "Glenmorangie Dornoch",
    "rating": 8.48,
    "STDEV": 0.64,
    "#": 9,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 42,
    "whisky": "Glenmorangie Duthac",
    "rating": 8.51,
    "STDEV": 0.25,
    "#": 9,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 42,
    "whisky": "Glenmorangie Ealanta",
    "rating": 8.88,
    "STDEV": 0.28,
    "#": 16,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "F",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 42,
    "whisky": "Glenmorangie Lasanta",
    "rating": 8.4,
    "STDEV": 0.35,
    "#": 22,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 42,
    "whisky": "Glenmorangie Milsean",
    "rating": 8.45,
    "STDEV": 0.39,
    "#": 12,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 42,
    "whisky": "Glenmorangie Nectar d'Or",
    "rating": 8.74,
    "STDEV": 0.27,
    "#": 22,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 42,
    "whisky": "Glenmorangie Quinta Ruban",
    "rating": 8.57,
    "STDEV": 0.46,
    "#": 25,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 42,
    "whisky": "Glenmorangie Signet",
    "rating": 8.97,
    "STDEV": 0.38,
    "#": 22,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 42,
    "whisky": "Glenmorangie Spios Private Edition No 9",
    "rating": 8.76,
    "STDEV": 0.24,
    "#": 12,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 42,
    "whisky": "Glenmorangie The Taghta",
    "rating": 8.67,
    "STDEV": 0.29,
    "#": 11,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 42,
    "whisky": "Glenmorangie The Tarlogan",
    "rating": 8.62,
    "STDEV": 0.32,
    "#": 6,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 42,
    "whisky": "Glenmorangie The Tayne",
    "rating": 8.5,
    "STDEV": 0.21,
    "#": 10,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 42,
    "whisky": "Glenmorangie Tusail",
    "rating": 8.75,
    "STDEV": 0.31,
    "#": 16,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 43,
    "whisky": "Glenrothes Alba Reserve",
    "rating": 8.23,
    "STDEV": 0.43,
    "#": 9,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 43,
    "whisky": "Glenrothes Bourbon Cask Reserve",
    "rating": 8.14,
    "STDEV": 0.19,
    "#": 3,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 43,
    "whisky": "Glenrothes Elders' Reserve",
    "rating": 8.24,
    "STDEV": 0.71,
    "#": 7,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 43,
    "whisky": "Glenrothes Minister's Reserve",
    "rating": 8.71,
    "STDEV": 0.16,
    "#": 4,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 43,
    "whisky": "Glenrothes Peated Cask Reserve",
    "rating": 8.09,
    "STDEV": 0.57,
    "#": 3,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 43,
    "whisky": "Glenrothes Robur Reserve",
    "rating": 8.2,
    "STDEV": 0.4,
    "#": 6,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 43,
    "whisky": "Glenrothes Select Reserve",
    "rating": 7.77,
    "STDEV": 0.38,
    "#": 14,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 43,
    "whisky": "Glenrothes Sherry Cask Reserve",
    "rating": 8.09,
    "STDEV": 0.7,
    "#": 10,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 43,
    "whisky": "Glenrothes Vintage 1995 all bottlings",
    "rating": 8.62,
    "STDEV": 0.3,
    "#": 17,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 43,
    "whisky": "Glenrothes Vintage 1998 2014",
    "rating": 8.32,
    "STDEV": 0.59,
    "#": 12,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "F",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 43,
    "whisky": "Glenrothes Vintage 2001 all bottlings",
    "rating": 8.16,
    "STDEV": 0.62,
    "#": 9,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 43,
    "whisky": "Glenrothes Vintage Reserve NAS",
    "rating": 8.45,
    "STDEV": 0.23,
    "#": 7,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 44,
    "whisky": "Glenturret 10yo",
    "rating": 8.1,
    "STDEV": 0.49,
    "#": 13,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park 10yo",
    "rating": 8.51,
    "STDEV": 0.25,
    "#": 15,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park 12yo all reviews",
    "rating": 8.65,
    "STDEV": 0.21,
    "#": 26,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park 12yo reviews 2014-2017",
    "rating": 8.41,
    "STDEV": 0.42,
    "#": 15,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park 12yo reviews pre-2014",
    "rating": 8.76,
    "STDEV": 0.27,
    "#": 20,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park 12yo Saint Magnus",
    "rating": 8.42,
    "STDEV": 0.34,
    "#": 6,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park 12yo Viking Honour post-2017",
    "rating": 8.63,
    "STDEV": 0.15,
    "#": 7,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park 15yo",
    "rating": 8.69,
    "STDEV": 0.21,
    "#": 14,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park 15yo Fire",
    "rating": 8.73,
    "STDEV": 0.34,
    "#": 11,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park 15yo Freya",
    "rating": 8.71,
    "STDEV": 0.37,
    "#": 14,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park 15yo Loki",
    "rating": 8.99,
    "STDEV": 0.25,
    "#": 16,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park 16yo Odin",
    "rating": 9.06,
    "STDEV": 0.26,
    "#": 7,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park 16yo Thor",
    "rating": 8.88,
    "STDEV": 0.29,
    "#": 13,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park 17yo Ice",
    "rating": 8.7,
    "STDEV": 0.28,
    "#": 10,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park 17yo The Dark",
    "rating": 9.07,
    "STDEV": 0.38,
    "#": 12,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park 17yo The Light",
    "rating": 9.05,
    "STDEV": 0.23,
    "#": 6,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park 18yo",
    "rating": 9.04,
    "STDEV": 0.26,
    "#": 26,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park 21yo",
    "rating": 8.9,
    "STDEV": 0.39,
    "#": 17,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park 25yo",
    "rating": 9.17,
    "STDEV": 0.17,
    "#": 14,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park 30yo",
    "rating": 9.12,
    "STDEV": 0.41,
    "#": 13,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park 40yo",
    "rating": 9.16,
    "STDEV": 0.44,
    "#": 10,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park Dark Origins",
    "rating": 8.45,
    "STDEV": 0.49,
    "#": 22,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park Einar",
    "rating": 8.18,
    "STDEV": 0.4,
    "#": 9,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park Full Volume",
    "rating": 8.79,
    "STDEV": 0.23,
    "#": 12,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park Harald",
    "rating": 8.22,
    "STDEV": 0.45,
    "#": 13,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park Magnus 2017",
    "rating": 8.62,
    "STDEV": 0.17,
    "#": 8,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park Sigurd",
    "rating": 9.02,
    "STDEV": 0.31,
    "#": 7,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park Svein",
    "rating": 8.29,
    "STDEV": 0.4,
    "#": 12,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park Valknut",
    "rating": 8.62,
    "STDEV": 0.25,
    "#": 3,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 45,
    "whisky": "Highland Park Valkyrie",
    "rating": 8.61,
    "STDEV": 0.21,
    "#": 11,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 46,
    "whisky": "Inchgower 14yo F&F",
    "rating": 8.55,
    "STDEV": 0.53,
    "#": 8,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 47,
    "whisky": "Jura 10yo Origin",
    "rating": 8.05,
    "STDEV": 0.34,
    "#": 18,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 47,
    "whisky": "Jura 12yo Elixir",
    "rating": 8.3,
    "STDEV": 0.45,
    "#": 11,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 47,
    "whisky": "Jura 16yo",
    "rating": 7.96,
    "STDEV": 0.5,
    "#": 5,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 47,
    "whisky": "Jura 16yo Diurach's Own",
    "rating": 8.48,
    "STDEV": 0.36,
    "#": 15,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 47,
    "whisky": "Jura 18yo",
    "rating": 8.49,
    "STDEV": 0.34,
    "#": 3,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 47,
    "whisky": "Jura 21yo",
    "rating": 8.39,
    "STDEV": 0.71,
    "#": 7,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 47,
    "whisky": "Jura 21yo 200th Anniversary",
    "rating": 8.27,
    "STDEV": 0.77,
    "#": 4,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 47,
    "whisky": "Jura Brooklyn",
    "rating": 8.76,
    "STDEV": 0.21,
    "#": 6,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 47,
    "whisky": "Jura Prophecy",
    "rating": 8.65,
    "STDEV": 0.31,
    "#": 18,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 47,
    "whisky": "Jura Seven Wood",
    "rating": 8.21,
    "STDEV": 0.29,
    "#": 3,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 47,
    "whisky": "Jura Superstition",
    "rating": 8.27,
    "STDEV": 0.45,
    "#": 23,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 47,
    "whisky": "Jura Turas Mara",
    "rating": 7.9,
    "STDEV": 0.54,
    "#": 5,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 48,
    "whisky": "Lagavulin 12yo Cask Strength",
    "rating": 9.11,
    "STDEV": 0.16,
    "#": 23,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 48,
    "whisky": "Lagavulin 16yo",
    "rating": 9.17,
    "STDEV": 0.27,
    "#": 28,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 48,
    "whisky": "Lagavulin 8yo",
    "rating": 8.88,
    "STDEV": 0.24,
    "#": 21,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 48,
    "whisky": "Lagavulin Distiller's Edition All Vintages",
    "rating": 9.03,
    "STDEV": 0.23,
    "#": 24,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 49,
    "whisky": "Laphroaig 10yo",
    "rating": 8.85,
    "STDEV": 0.26,
    "#": 25,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 49,
    "whisky": "Laphroaig 10yo Cask Strength all batches",
    "rating": 8.96,
    "STDEV": 0.33,
    "#": 21,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 49,
    "whisky": "Laphroaig 15yo 200th Anniversary",
    "rating": 8.8,
    "STDEV": 0.31,
    "#": 17,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 49,
    "whisky": "Laphroaig 18yo",
    "rating": 9,
    "STDEV": 0.27,
    "#": 21,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 49,
    "whisky": "Laphroaig 21yo",
    "rating": 9.17,
    "STDEV": 0.28,
    "#": 10,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 49,
    "whisky": "Laphroaig 25yo",
    "rating": 9.1,
    "STDEV": 0.27,
    "#": 19,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 49,
    "whisky": "Laphroaig 27yo 57.4% 1980-2007 OB 5 Oloroso Casks 972 Bts",
    "rating": 9.39,
    "STDEV": 0.2,
    "#": 4,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 49,
    "whisky": "Laphroaig An Cuan Mor",
    "rating": 8.84,
    "STDEV": 0.17,
    "#": 14,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 49,
    "whisky": "Laphroaig Cairdeas 2013 Port Wood",
    "rating": 8.89,
    "STDEV": 0.47,
    "#": 14,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 49,
    "whisky": "Laphroaig Cairdeas 2014 Amontillado",
    "rating": 8.94,
    "STDEV": 0.21,
    "#": 11,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 49,
    "whisky": "Laphroaig Cairdeas 2015",
    "rating": 9.12,
    "STDEV": 0.24,
    "#": 11,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 49,
    "whisky": "Laphroaig Cairdeas 2016 Madeira",
    "rating": 8.84,
    "STDEV": 0.41,
    "#": 13,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 49,
    "whisky": "Laphroaig Cairdeas 2017",
    "rating": 8.67,
    "STDEV": 0.27,
    "#": 9,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 49,
    "whisky": "Laphroaig Cairdeas 2018 Fino",
    "rating": 8.83,
    "STDEV": 0.24,
    "#": 6,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 49,
    "whisky": "Laphroaig Lore",
    "rating": 8.62,
    "STDEV": 0.3,
    "#": 17,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 49,
    "whisky": "Laphroaig PX Triple Matured",
    "rating": 8.81,
    "STDEV": 0.56,
    "#": 17,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 49,
    "whisky": "Laphroaig QA Cask",
    "rating": 7.27,
    "STDEV": 0.51,
    "#": 11,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 49,
    "whisky": "Laphroaig Quarter Cask",
    "rating": 8.31,
    "STDEV": 0.27,
    "#": 24,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 49,
    "whisky": "Laphroaig Select",
    "rating": 8.04,
    "STDEV": 0.36,
    "#": 15,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 49,
    "whisky": "Laphroaig Triple Wood",
    "rating": 8.69,
    "STDEV": 0.34,
    "#": 20,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 50,
    "whisky": "Linkwood 12yo F&F",
    "rating": 8.58,
    "STDEV": 0.33,
    "#": 7,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 51,
    "whisky": "Loch Lomond",
    "rating": 7.69,
    "STDEV": 0.44,
    "#": 12,
    "cost": "$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 52,
    "whisky": "Longmorn 15yo",
    "rating": 8.83,
    "STDEV": 0.38,
    "#": 8,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 52,
    "whisky": "Longmorn 16yo",
    "rating": 8.74,
    "STDEV": 0.33,
    "#": 18,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 52,
    "whisky": "Longmorn Distiller's Choice",
    "rating": 8.04,
    "STDEV": 0.43,
    "#": 9,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan 10yo Fine Oak",
    "rating": 8.25,
    "STDEV": 0.3,
    "#": 19,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan 10yo Sherry Oak",
    "rating": 8.42,
    "STDEV": 0.31,
    "#": 11,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan 10yo Sherry Oak Cask Strength",
    "rating": 8.87,
    "STDEV": 0.53,
    "#": 6,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan 12yo Double Cask",
    "rating": 8.41,
    "STDEV": 0.4,
    "#": 11,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan 12yo Fine Oak",
    "rating": 8.45,
    "STDEV": 0.37,
    "#": 17,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan 12yo Sherry Oak",
    "rating": 8.51,
    "STDEV": 0.35,
    "#": 23,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan 15yo Fine Oak",
    "rating": 8.44,
    "STDEV": 0.51,
    "#": 12,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan 17yo Fine Oak",
    "rating": 8.78,
    "STDEV": 0.5,
    "#": 11,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan 18yo Fine Oak",
    "rating": 8.67,
    "STDEV": 0.21,
    "#": 7,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan 18yo Sherry Oak",
    "rating": 8.89,
    "STDEV": 0.32,
    "#": 19,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan 21yo Fine Oak",
    "rating": 8.5,
    "STDEV": 0.4,
    "#": 6,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan 25yo Fine Oak",
    "rating": 8.61,
    "STDEV": 0.26,
    "#": 3,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan 25yo Sherry Oak",
    "rating": 8.83,
    "STDEV": 0.46,
    "#": 8,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan 30yo Fine Oak",
    "rating": 8.54,
    "STDEV": 0.36,
    "#": 6,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan Amber",
    "rating": 8.28,
    "STDEV": 0.36,
    "#": 13,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan Cask Strength",
    "rating": 8.93,
    "STDEV": 0.36,
    "#": 16,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan Classic Cut",
    "rating": 8.74,
    "STDEV": 0.17,
    "#": 8,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan Edition No. 1",
    "rating": 8.79,
    "STDEV": 0.47,
    "#": 10,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan Edition No. 2",
    "rating": 8.91,
    "STDEV": 0.21,
    "#": 16,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan Edition No. 3",
    "rating": 8.85,
    "STDEV": 0.17,
    "#": 10,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan Edition No. 4",
    "rating": 9,
    "STDEV": 0.37,
    "#": 8,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan Gold",
    "rating": 8.2,
    "STDEV": 0.29,
    "#": 17,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan Rare Cask",
    "rating": 8.75,
    "STDEV": 0.4,
    "#": 11,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan Reflexion",
    "rating": 8.75,
    "STDEV": 0.35,
    "#": 4,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan Ruby",
    "rating": 8.75,
    "STDEV": 0.21,
    "#": 13,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan Select Oak",
    "rating": 8.21,
    "STDEV": 0.33,
    "#": 12,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan Sienna",
    "rating": 8.69,
    "STDEV": 0.33,
    "#": 18,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 53,
    "whisky": "Macallan Whisky Maker's Edition",
    "rating": 8.53,
    "STDEV": 0.35,
    "#": 14,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 54,
    "whisky": "Mannochmore 12yo F&F",
    "rating": 8.27,
    "STDEV": 0.14,
    "#": 4,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 55,
    "whisky": "Miltonduff 10yo F&F",
    "rating": 8.07,
    "STDEV": 0.41,
    "#": 4,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 56,
    "whisky": "Mortlach 15yo Gordon & MacPhail",
    "rating": 8.68,
    "STDEV": 0.35,
    "#": 13,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 56,
    "whisky": "Mortlach 16yo",
    "rating": 9.08,
    "STDEV": 0.12,
    "#": 3,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 56,
    "whisky": "Mortlach 16yo F&F",
    "rating": 8.69,
    "STDEV": 0.28,
    "#": 10,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 56,
    "whisky": "Mortlach 18yo",
    "rating": 8.69,
    "STDEV": 0.61,
    "#": 10,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 56,
    "whisky": "Mortlach Rare Old",
    "rating": 8.44,
    "STDEV": 0.38,
    "#": 16,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 56,
    "whisky": "Mortlach Special Strength",
    "rating": 8.77,
    "STDEV": 0.59,
    "#": 6,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 57,
    "whisky": "Oban 14yo",
    "rating": 8.5,
    "STDEV": 0.38,
    "#": 23,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 57,
    "whisky": "Oban 18yo",
    "rating": 8.73,
    "STDEV": 0.2,
    "#": 15,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 57,
    "whisky": "Oban 21yo",
    "rating": 8.98,
    "STDEV": 0.2,
    "#": 5,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 57,
    "whisky": "Oban Distillers Edition all vintages",
    "rating": 8.65,
    "STDEV": 0.24,
    "#": 16,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 57,
    "whisky": "Oban Little Bay",
    "rating": 8.37,
    "STDEV": 0.35,
    "#": 15,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 58,
    "whisky": "Old Pulteney 12yo",
    "rating": 8.44,
    "STDEV": 0.25,
    "#": 26,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 58,
    "whisky": "Old Pulteney 15yo",
    "rating": 8.96,
    "STDEV": 0.21,
    "#": 4,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 58,
    "whisky": "Old Pulteney 17yo",
    "rating": 8.77,
    "STDEV": 0.24,
    "#": 20,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 58,
    "whisky": "Old Pulteney 18yo",
    "rating": 8.5,
    "STDEV": 0.63,
    "#": 3,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 58,
    "whisky": "Old Pulteney 21yo",
    "rating": 8.78,
    "STDEV": 0.43,
    "#": 20,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 58,
    "whisky": "Old Pulteney 25yo",
    "rating": 8.95,
    "STDEV": 0.18,
    "#": 6,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 58,
    "whisky": "Old Pulteney Huddart",
    "rating": 8.64,
    "STDEV": 0.22,
    "#": 3,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 58,
    "whisky": "Old Pulteney Navigator",
    "rating": 8.34,
    "STDEV": 0.36,
    "#": 13,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 59,
    "whisky": "Royal Brackla 12yo",
    "rating": 8.23,
    "STDEV": 0.47,
    "#": 12,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 59,
    "whisky": "Royal Brackla 16yo",
    "rating": 8.56,
    "STDEV": 0.29,
    "#": 11,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 59,
    "whisky": "Royal Brackla 21yo",
    "rating": 8.8,
    "STDEV": 0.21,
    "#": 4,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 60,
    "whisky": "Royal Lochnagar 12yo",
    "rating": 7.97,
    "STDEV": 0.29,
    "#": 15,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 61,
    "whisky": "Scapa 16yo",
    "rating": 8.28,
    "STDEV": 0.29,
    "#": 16,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 61,
    "whisky": "Scapa Skiren",
    "rating": 7.94,
    "STDEV": 0.57,
    "#": 12,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 62,
    "whisky": "Speyburn 10yo",
    "rating": 8.09,
    "STDEV": 0.32,
    "#": 21,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 63,
    "whisky": "Speyside 10yo",
    "rating": 7.71,
    "STDEV": 0.62,
    "#": 4,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 63,
    "whisky": "Speyside 12yo",
    "rating": 7.86,
    "STDEV": 0.63,
    "#": 10,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 64,
    "whisky": "Hazelburn 12yo Springbank",
    "rating": 8.6,
    "STDEV": 0.32,
    "#": 19,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 64,
    "whisky": "Hazelburn 8yo Springbank",
    "rating": 8.35,
    "STDEV": 0.39,
    "#": 11,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 64,
    "whisky": "Springbank 10yo",
    "rating": 8.72,
    "STDEV": 0.26,
    "#": 22,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 64,
    "whisky": "Springbank 11yo Local Barley",
    "rating": 9.06,
    "STDEV": 0.21,
    "#": 10,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 64,
    "whisky": "Springbank 12yo Cask Strength",
    "rating": 8.87,
    "STDEV": 0.25,
    "#": 19,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 64,
    "whisky": "Springbank 12yo Green",
    "rating": 8.76,
    "STDEV": 0.29,
    "#": 8,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 64,
    "whisky": "Springbank 13yo Green",
    "rating": 8.73,
    "STDEV": 0.31,
    "#": 6,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 64,
    "whisky": "Springbank 15yo",
    "rating": 8.74,
    "STDEV": 0.22,
    "#": 20,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 64,
    "whisky": "Springbank 16yo Local Barley",
    "rating": 9.12,
    "STDEV": 0.19,
    "#": 10,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 64,
    "whisky": "Springbank 18yo",
    "rating": 8.92,
    "STDEV": 0.2,
    "#": 21,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 64,
    "whisky": "Springbank 21yo",
    "rating": 8.99,
    "STDEV": 0.26,
    "#": 12,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 64,
    "whisky": "Springbank CV",
    "rating": 8.26,
    "STDEV": 0.37,
    "#": 8,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 65,
    "whisky": "Strathisla 12yo",
    "rating": 8.21,
    "STDEV": 0.31,
    "#": 14,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 66,
    "whisky": "Strathmill 12yo",
    "rating": 8.37,
    "STDEV": 0.28,
    "#": 3,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 66,
    "whisky": "Strathmill 25yo",
    "rating": 8.78,
    "STDEV": 0.42,
    "#": 4,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 67,
    "whisky": "Talisker 10yo",
    "rating": 8.86,
    "STDEV": 0.23,
    "#": 25,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 67,
    "whisky": "Talisker 18yo",
    "rating": 9.14,
    "STDEV": 0.21,
    "#": 20,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 67,
    "whisky": "Talisker 25yo",
    "rating": 9,
    "STDEV": 0.26,
    "#": 18,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 67,
    "whisky": "Talisker 30yo",
    "rating": 9.18,
    "STDEV": 0.33,
    "#": 13,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 67,
    "whisky": "Talisker 35yo",
    "rating": 9.3,
    "STDEV": 0.32,
    "#": 3,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 67,
    "whisky": "Talisker 57 North",
    "rating": 8.92,
    "STDEV": 0.21,
    "#": 19,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 67,
    "whisky": "Talisker 8yo 2018 Special Release",
    "rating": 8.96,
    "STDEV": 0.36,
    "#": 5,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 67,
    "whisky": "Talisker Dark Storm",
    "rating": 8.64,
    "STDEV": 0.18,
    "#": 13,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 67,
    "whisky": "Talisker Distiller's Edition all editions",
    "rating": 8.91,
    "STDEV": 0.23,
    "#": 24,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 67,
    "whisky": "Talisker Port Ruighe",
    "rating": 8.53,
    "STDEV": 0.37,
    "#": 19,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 67,
    "whisky": "Talisker Skye",
    "rating": 8.42,
    "STDEV": 0.29,
    "#": 12,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 67,
    "whisky": "Talisker Storm",
    "rating": 8.58,
    "STDEV": 0.26,
    "#": 19,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 68,
    "whisky": "Tamdhu 10yo",
    "rating": 8.29,
    "STDEV": 0.58,
    "#": 18,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 68,
    "whisky": "Tamdhu 30yo MacPhail Collection 2009",
    "rating": 9.09,
    "STDEV": 0.1,
    "#": 3,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 68,
    "whisky": "Tamdhu Batch Strength both batches",
    "rating": 8.67,
    "STDEV": 0.44,
    "#": 12,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "A",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 69,
    "whisky": "Tamnavulin 12yo",
    "rating": 7.7,
    "STDEV": 0.79,
    "#": 4,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 70,
    "whisky": "Teaninich 10yo F&F",
    "rating": 8.27,
    "STDEV": 0.2,
    "#": 4,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "F",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 71,
    "whisky": "Tobermory 10yo",
    "rating": 8.24,
    "STDEV": 0.41,
    "#": 23,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "F",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 71,
    "whisky": "Tobermory 15yo",
    "rating": 8.58,
    "STDEV": 0.32,
    "#": 16,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 72,
    "whisky": "Tomatin 12yo",
    "rating": 8.05,
    "STDEV": 0.45,
    "#": 20,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 72,
    "whisky": "Tomatin 14yo Portwood",
    "rating": 8.61,
    "STDEV": 0.32,
    "#": 11,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 72,
    "whisky": "Tomatin 15yo",
    "rating": 8.39,
    "STDEV": 0.38,
    "#": 7,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 72,
    "whisky": "Tomatin 18yo",
    "rating": 8.7,
    "STDEV": 0.2,
    "#": 14,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 72,
    "whisky": "Tomatin 40yo",
    "rating": 8.93,
    "STDEV": 0.38,
    "#": 3,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 72,
    "whisky": "Tomatin Cask Strength",
    "rating": 8.34,
    "STDEV": 0.48,
    "#": 10,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 72,
    "whisky": "Tomatin Cu Bocan",
    "rating": 8.04,
    "STDEV": 0.38,
    "#": 13,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 72,
    "whisky": "Tomatin Cu Bocan 1989 Limited Edition",
    "rating": 8.93,
    "STDEV": 0.27,
    "#": 4,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "I",
    "cluster": "I",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 72,
    "whisky": "Tomatin Cu Bocan 2005 Limited Edition",
    "rating": 8.69,
    "STDEV": 0.4,
    "#": 3,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 72,
    "whisky": "Tomatin Cu Bocan Sherry Edition",
    "rating": 8.33,
    "STDEV": 0.3,
    "#": 4,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 72,
    "whisky": "Tomatin Cu Bocan Virgin Oak Edition",
    "rating": 8.49,
    "STDEV": 0.48,
    "#": 3,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "H",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 72,
    "whisky": "Tomatin Decades",
    "rating": 8.92,
    "STDEV": 0.52,
    "#": 9,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 72,
    "whisky": "Tomatin Legacy",
    "rating": 8.09,
    "STDEV": 0.43,
    "#": 11,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 72,
    "whisky": "Tomatin Oloroso Sherry 1995",
    "rating": 8.56,
    "STDEV": 0.56,
    "#": 4,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 73,
    "whisky": "Tomintoul 10yo",
    "rating": 7.82,
    "STDEV": 0.51,
    "#": 16,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 73,
    "whisky": "Tomintoul 12yo Oloroso Sherry",
    "rating": 8.19,
    "STDEV": 0.56,
    "#": 11,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 73,
    "whisky": "Tomintoul 16yo",
    "rating": 8.29,
    "STDEV": 0.4,
    "#": 18,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 73,
    "whisky": "Tomintoul 25yo",
    "rating": 8.36,
    "STDEV": 0.71,
    "#": 6,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 73,
    "whisky": "Tomintoul Peaty Tang",
    "rating": 7.86,
    "STDEV": 0.66,
    "#": 12,
    "cost": "$$",
    "class": "SingleMalt-like",
    "Super Cluster": "J",
    "cluster": "J",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 74,
    "whisky": "Tormore 12yo",
    "rating": 7.91,
    "STDEV": 0.38,
    "#": 9,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 74,
    "whisky": "Tormore 14yo",
    "rating": 8.13,
    "STDEV": 0.34,
    "#": 5,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 75,
    "whisky": "Tullibardine 1993 Port",
    "rating": 7.97,
    "STDEV": 0.37,
    "#": 5,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "B",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 75,
    "whisky": "Tullibardine 1993 Sauternes",
    "rating": 8.17,
    "STDEV": 0.33,
    "#": 5,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 75,
    "whisky": "Tullibardine 20yo",
    "rating": 8.59,
    "STDEV": 0.29,
    "#": 5,
    "cost": "$$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 75,
    "whisky": "Tullibardine 225 Sauternes Finish",
    "rating": 8.33,
    "STDEV": 0.39,
    "#": 6,
    "cost": "$$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 75,
    "whisky": "Tullibardine 228 Burgundy Finish",
    "rating": 8.06,
    "STDEV": 0.32,
    "#": 5,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 75,
    "whisky": "Tullibardine 25yo",
    "rating": 8.43,
    "STDEV": 0.37,
    "#": 7,
    "cost": "$$$$$+",
    "class": "SingleMalt-like",
    "Super Cluster": "EF",
    "cluster": "E",
    "Country": "Ireland",
    "Type": "Malt"
  },
  {
    "id": 75,
    "whisky": "Tullibardine 500 Sherry Finish",
    "rating": 8.19,
    "STDEV": 0.3,
    "#": 8,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "ABC",
    "cluster": "C",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 75,
    "whisky": "Tullibardine Aged Oak Edition",
    "rating": 8.05,
    "STDEV": 0.43,
    "#": 9,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 75,
    "whisky": "Tullibardine Sovereign",
    "rating": 8.18,
    "STDEV": 0.27,
    "#": 10,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  },
  {
    "id": 75,
    "whisky": "Tullibardine Vintage 1993",
    "rating": 8.26,
    "STDEV": 0.43,
    "#": 10,
    "cost": "$$$",
    "class": "SingleMalt-like",
    "Super Cluster": "GH",
    "cluster": "G",
    "Country": "Scotland",
    "Type": "Malt"
  }
]